// UTILS
import commonUtils from '../../../utils/common'
// API
import CustomerAPI from 'api/customers'
import SettingAPI from 'api/settings'
import _ from 'lodash'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { LONG_HAUL } from 'constants/bookingConstants'
import { isEditBooking, setAttachments, getParamFromURL } from 'utils/booking/common'
import { attachmentsActionsCreator } from 'store/toolkit/newBooking/attachments.reducer'
import { isLoginStep3Creator } from 'store/toolkit/newBooking/isLoginStep3.reducer'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import { requireSignaturesActionsCreator } from 'store/toolkit/requireSignatures/requireSignatures.reducer'
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import { parseJwt, setAccessToken } from 'utils/crossStorage'
import CommonUtils from '../../../utils/common'
import store from 'store/store'
import currentLocationUtils from 'utils/common/getCurrentLocation'

export const updateCustomer = (customer) => currentCustomerActionsCreator.updateCustomer(customer)

export const updateCurrentCustomer = (authenticationToken, params, extraUpdate) => async (dispatch) => {
  const infoUser = await CustomerAPI.getCustomer(authenticationToken, params)
  if (infoUser.statusApi === 401) setAccessToken('')
  dispatch(updateCustomer({ ...infoUser, ...extraUpdate }))
}

export const joinCustomerChat = (bookingID, authenticationToken, callback) => () => {
  CustomerAPI.postJoinCustomerChat(bookingID, authenticationToken, (response) => {
    callback(response)
  })
}

export const handleSetIsLoginStep3 = (isLoginStep3) => (dispatch) =>
  dispatch(isLoginStep3Creator.setIsLoginStep3(isLoginStep3))

const actionDispatchByPage = (responseCashBack, enoughDataCheckCashBack, data, bookings, dispatch, batch) => {
  const cashBackReward = {
    cash_back_reward: responseCashBack,
    enough_data_check_cashback: enoughDataCheckCashBack,
  }
  if (commonUtils.isMultiple()) {
    dispatch(bookingsActionCreators.updateBooking(bookings[data.idx]?.id, cashBackReward))
    return
  }
  if (commonUtils.isBatchEZ() || commonUtils.isSmartLoad()) {
    const bookingsChange = batch.bookings[data.idx]
    dispatch(
      batchActionsCreator.updateBooking({
        booking: {
          temp_id: bookingsChange?.temp_id,
          cash_back_reward: responseCashBack,
          enough_data_check_cashback: enoughDataCheckCashBack,
        },
      })
    )
    return
  }
  dispatch(bookingActionCreators.updateBookingAttributes(cashBackReward))
}
export const calculateCustomerCashbackPercent =
  (data, isHaveLHAddress = false) =>
  async (dispatch, getState) => {
    const { currentCustomer, extraInfos, batch } = getState()
    const isCompany = currentCustomer.current_company_id
    const filteredLocations = data?.locations_attributes[0]
    const result = []
    if (filteredLocations) {
      result.push({
        latitude: filteredLocations.lat,
        longitude: filteredLocations.lng,
        name: filteredLocations.name,
      })
    }
    const params = {
      country_code: extraInfos.country_code,
      company_id: currentCustomer.current_company_id || currentCustomer.id,
      account_type: isCompany ? 'company' : 'customer',
      locations_attributes: result,
      time_type: isHaveLHAddress ? LONG_HAUL : data.time_type,
      vehicle_type_id: data.vehicle_type_id,
    }
    if (params.locations_attributes[0]?.latitude && params.time_type && params.vehicle_type_id) {
      const { data: cashbackData } = await CustomerAPI.postCalculateCashbackPercent(params)
      const responseCashBack = cashbackData.cashback_credit_earn || null
      actionDispatchByPage(responseCashBack, true, data, batch.bookings, dispatch, batch)
    } else {
      actionDispatchByPage(null, false, data, batch.bookings, dispatch, batch)
    }
  }

export const getCustomerCreditAmount =
  (acceptEmptyCredit = false, callback) =>
  async (dispatch, getState) => {
    const { currentCustomer, extraInfos } = getState()
    const companyId = currentCustomer.current_company_id
    const id = companyId || currentCustomer.id
    if (!id) {
      if (typeof callback === 'function') {
        callback()
      }
    } else {
      const params = {
        id,
        country_code: extraInfos.country_code,
        account_type: companyId ? 'company' : 'customer',
      }
      const resWallet = await CustomerAPI.getCreditBalance(params)
      if (resWallet.status === 200) {
        const isBpPostPaid = currentCustomer?.allow_post_payment

        const cashbackWallet = resWallet?.data?.cashback_wallet
        const creditWallet = resWallet?.data?.credit_wallet
        if (!_.isEmpty(creditWallet)) {
          let credit = creditWallet
          if (isBpPostPaid) {
            credit = {
              ...cashbackWallet,
              balance: creditWallet.amount,
            }
          }
          credit.acceptEmptyCredit = acceptEmptyCredit
          dispatch(updateCustomer({ credit }))

          if (typeof callback === 'function') {
            callback()
          }
        }
      }
    }
  }

export const getCustomerSettings = (accessToken, areaId) => (dispatch, getState) => {
  const { currentCustomer, extraInfos, attachments: attachmentsStore } = getState()

  const finalCountryCode = extraInfos.country_code
  const finalAreaId = areaId || extraInfos.area_id

  if (!finalCountryCode || !finalAreaId) return

  const query = {
    company_id: currentCustomer.current_company_id || 0,
    include: [
      'send_sms_to_recipients',
      'show_phone_number_to_driver',
      'default_note',
      'booking_attachments',
      'ceb_enabled_feature',
      'update_booking_interval',
      'analytic_settings'
    ],
    area_id: finalAreaId,
    include_fleet_driver: true,
  }
  const token = accessToken || window.localStorage.getItem('access_token') || ''

  SettingAPI.getCustomerSettingsApi(
    token,
    query,
    (response) => {
      if (response?.data) {
        const dynamicTexts = response.data?.dynamic_text_json
        const extraInfosUpdate = {
          ...response.data,
          dynamicTexts,
          area_id: finalAreaId,
          id: finalAreaId,
          address_components: response.data?.address_component_for_displaying || [],
          enable_address_components: response.data?.enable_display_address_component_configurations,
        }
        if (!isEditBooking()) {
          dispatch(
            requireSignaturesActionsCreator.updateRequireSignatures(response.data?.booking_previous_require_signatures)
          )
        }

        const result = response.data || {}

        if (currentCustomer.id) {
          dispatch(
            updateCustomer({
              booking_attachments: result.booking_attachments,
              send_sms_to_recipients: result.send_sms_to_recipients,
              show_phone_number_to_driver: result.show_phone_number_to_driver,
              ceb_enabled_feature: result.ceb_enabled_feature,
              cs_working_hours: result.cs_working_hours,
              update_booking_interval: result.update_booking_interval,
              allow_post_payment: result.allow_post_payment,
            })
          )
        }

        dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosUpdate))

        if (commonUtils.isSingle() && _.isEmpty(attachmentsStore)) {
          let attachments = []
          if (currentCustomer.id) {
            if (result.booking_attachments) {
              attachments = setAttachments(result.booking_attachments)
            }
          } else {
            attachments = setAttachments([])
          }
          dispatch(attachmentsActionsCreator.updateAttachments(attachments))
        }
      }
    },
    finalCountryCode
  )
}

export const getCurrentCustomerProps = async (batchType) => {
  const {
    currentCustomer,
    currentArea: { id },
  } = store.getState()
  const dispatch = store.dispatch
  const areaId = window.localStorage.getItem('areaId')
  const params = {
    batch_type: batchType,
    area_id: id || areaId,
    ...(currentCustomer.last_login_employ_id && { company_id: currentCustomer.last_login_employ_id }),
  }
  const { status, data } = await CustomerAPI.getCurrentCustomerProps(params)
  if (status === 200) {
    const customer = { ...data }
    if (!data.authentication_token) {
      delete customer.authentication_token
    }

    if (currentCustomer.id) {
      delete customer.id
    }

    dispatch(currentCustomerActionsCreator.updateCustomer(data))
  }
}

export const getCurrentCustomer =
  (authenticationToken, finalAreaId, lastLoginEmployId) => async (dispatch, getState) => {
    if (!finalAreaId || !authenticationToken) return
    const params = {
      area_id: finalAreaId,
      include: [
        'companies',
        'company',
        'employs',
        'send_sms_to_recipients',
        'show_phone_number_to_driver',
        'has_bookings', // use for My Bookings
      ],
      company_id: lastLoginEmployId,
    }

    const res = await CustomerAPI.getCustomer(authenticationToken, params)
    if (res?.statusApi === 401) {
      setAccessToken('')
      window.location.reload()
    } else if (res?.statusApi === 500) {
      const countryCode = getParamFromURL('country_code')
      dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
    } else if (!_.isEmpty(res)) {
      //handle for case token jwt and token last_login different need to convert new token or logout
      const decodeToken = parseJwt(authenticationToken)
      const companyIdFromToken = +decodeToken?.company_id || 0
      const companyIdNow = +res?.last_login_employ_id || 0
      let locationOfCustomer;

      if (authenticationToken && companyIdFromToken !== companyIdNow) {
        const result = await CustomerAPI.reConvertToken()
        if (result.status === 200 && result?.data?.access_token) {
          const decodeConvertToken = parseJwt(result.data.access_token)
          const companyIdFromReconvertToken = +decodeConvertToken?.company_id || 0
          const companyIdNow = +res?.last_login_employ_id || 0
          if (companyIdFromReconvertToken !== companyIdNow) {
            CommonUtils.handleSignOut(() => {
              window.location.reload()
            })
          } else {
            setAccessToken(result.data.access_token)
          }
        }
      }

      try {
        const { currLat: latitude, currLong: longitude } = await Promise.race([currentLocationUtils.getCurrentLocationOfDevice(), new Promise((_, reject) => setTimeout(() => reject(new Error('Request timed out')), 500))]);
        if (latitude || longitude) {
          locationOfCustomer = { latitude, longitude }
        }
      } catch (error) {
          console.log(error)
      }

      dispatch(
        updateCustomer({
          ...res,
          authentication_token: authenticationToken,
          area_id: finalAreaId,
          current_company_id: res.last_login_employ_id || 0,
          location_of_customer: locationOfCustomer || {},
        })
      )
    }
  }
