import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useLayoutEffect,
  useEffect,
  ReactNode,
  TextareaHTMLAttributes,
  SetStateAction,
} from 'react'
import { createPortal } from 'react-dom'
import debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid'

import DropdownList from './DropdownList'
import useOutsideHandler from 'hooks/useOutsideHandler'
import clsx from 'utils/common/clsx'
import usePortal from 'hooks/usePortal'
import useElBoundingRect from 'hooks/useElBoundingRect'
import locationUtils from 'utils/common/location'
import { X_USE_CASE } from 'constants/CommonConstant'

//isShow = false for case don't show no-result, but show show more and contact cs
export const NO_RESULTS = {
  id: 'no-results',
  isShow: true,
}

interface IAddressInput extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode
  onChange?: any
  extraLeftSidePositionDropdown: number
  onClickRecentLocation?: any
  onClickPredictLocation?: any
  onKeyUp?: any
  onKeyDown?: any
  onFocus?: any
  defaultValue: string
  onlyPredicts?: boolean
  className?: string
  extraInfos?: any
  recentLocations?: any
  onClick?: any
  isForceRerender?: boolean
  clickShowMore?: any
  classTextArea?: string
  classDropdown?: string
}

function toggleDropdown({ focusing, onlyPredicts, predicts, recents, showPredicts }: any) {
  if (!focusing) {
    return {
      showDropdown: false,
      dropdownList: [],
    }
  }

  if (onlyPredicts) {
    return {
      showDropdown: showPredicts && predicts.length > 0,
      dropdownList: predicts,
    }
  }

  return {
    showDropdown: showPredicts ? predicts.length > 0 : recents.length > 0,
    dropdownList: showPredicts ? predicts : recents,
  }
}

const AddressInput = forwardRef(
  (
    {
      children,
      onClickRecentLocation,
      onClickPredictLocation,
      onChange,
      // onAutoComplete,
      // onEnter,
      onKeyDown,
      onFocus,
      onClick,
      onKeyUp,
      defaultValue,
      onlyPredicts,
      className,
      extraLeftSidePositionDropdown,
      extraInfos,
      recentLocations,
      isForceRerender,
      clickShowMore,
      classTextArea,
      classDropdown,
      ...props
    }: IAddressInput,
    ref
  ) => {
    const inputRef: any = useRef(null)
    const dropdownRef = useRef(null)
    const [currentPositionDropdown, setCurrentPositionDropdown] = useState(-1) // -1 is un-selected in dropdown
    const isCurrentPositionDropdownActive = currentPositionDropdown !== -1
    const sessionToken = useRef(uuidv4())
    const [inputValue, setInputValue] = useState(defaultValue || '')
    const prevInputValueRef = useRef<any>(null)
    const [predictionLocations, setPredictionLocations] = useState([])
    const [isInputFocused, setIsInputFocused] = useState(false)
    const isPaste = useRef<any>(null)
    const [wrapperRef, wrapperRect, rerender]: any = useElBoundingRect()
    const portalRef = usePortal('dropdown-placeholder')
    const showPredicts = prevInputValueRef.current ? inputValue.length > 0 : false

    const settingShowMore = extraInfos.show_more_autocomplete_result
    const { showDropdown, dropdownList } = toggleDropdown({
      focusing: isInputFocused,
      showPredicts,
      predicts: predictionLocations,
      recents: !inputValue ? recentLocations : [],
      onlyPredicts,
    })

    // recalculate the dropdown position when layout changes
    useEffect(() => {
      rerender()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInputFocused, predictionLocations, recentLocations])

    useEffect(() => {
      if (defaultValue !== inputValue) {
        setInputValue(defaultValue)
        setPredictionLocations([])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    useEffect(() => {
      if (showDropdown && inputValue) {
        if (isCurrentPositionDropdownActive) {
          setInputValue(dropdownList[currentPositionDropdown].name)
        } else {
          setInputValue(prevInputValueRef.current)
          if (!showPredicts && !isCurrentPositionDropdownActive) {
            setInputValue(defaultValue)
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPositionDropdown])

    useOutsideHandler([inputRef, dropdownRef], () => {
      setIsInputFocused(false)
    })

    useLayoutEffect(() => {
      if (inputRef.current) {
        inputRef.current.style.height = 0
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
      }
    }, [inputValue, isForceRerender])

    useImperativeHandle(ref, () => ({
      textarea: inputRef.current,
      wrapper: wrapperRef.current,
    }),[inputRef, wrapperRef])

    const noResults = (xUseCase?: any) => {
      // alway show no-result for autocomplete google if empty
      if (xUseCase) {
        return [{ ...NO_RESULTS, xUseCase }]
      }
      if (!settingShowMore) {
        return [NO_RESULTS]
      }
      return [{ ...NO_RESULTS, isShow: false }]
    }

    const getPredictLocations = async (val: string, xUseCase?: string) => {
      const params = locationUtils.parseParamToGeocode(val, extraInfos?.country_code?.toLowerCase())
      if (params.latlng) return
      const result = await locationUtils.getAutoCompleteAddress({
        keyword: val,
        sessionToken: sessionToken.current,
        extraInfos,
        xUseCase,
      })
      // in case input is empty but the callback is still triggered because of the last call...
      // ... we check the current input value if it's empty we ignore the current result
      if (result) {
        const hasData = inputRef.current.value && result.length
        const locations = hasData ? result : noResults(xUseCase)
        // show no result when empty
        setPredictionLocations(locations)

        // if (hasInputValue) {
        //   onAutoComplete(locations)
        // }
      }
    }

    const delayGetPredictLocations = useCallback(
      debounce((val) => {
        getPredictLocations(val)
      }, 300),
      []
    )

    const delayGetPredictLocationWidthSearch = async (val: any) => {
      const params = locationUtils.parseParamToGeocode(val, extraInfos?.country_code?.toLowerCase())
      if (params.latlng) return

      const res = await locationUtils.getListSearchAddress(val, extraInfos, params)
      if (res) {
        const hasData = inputRef.current.value && res.length
        // show no result when empty
        const locations = hasData ? res : noResults()
        setPredictionLocations(locations)

        // if (hasInputValue) {
        //   onAutoComplete(locations)
        // }
      }
    }

    const focusTextInput = (e: any) => {
      setIsInputFocused(true)
      if (onFocus) {
        onFocus(e)
      }
    }

    const onClickTextArea = (e: any) => {
      focusTextInput(e)
      if (onClick) {
        onClick(e)
      }
    }

    const checkItemToSetPredictLocation = async (item: any) => {
      if (item?.address_components?.length) {
        onClickPredictLocation(item)
      } else {
        const result = await locationUtils.getPlaceDetail(item, sessionToken.current)
        if (result) {
          onClickPredictLocation(result)
        }
      }
      setPredictionLocations([])
    }

    const handleKeyDown = async (e: any) => {
      if (['ArrowUp', 'ArrowDown'].includes(e.key) && showDropdown) {
        setCurrentPositionDropdown((prePosition) => {
          const size = dropdownList.length - 1
          if (dropdownList.length === 1 && dropdownList[0].id === NO_RESULTS.id) return -1
          if (e.key === 'ArrowDown' && prePosition === size) return -1
          if (e.key === 'ArrowUp' && prePosition === -1) return size
          const position = e.key === 'ArrowDown' ? currentPositionDropdown + 1 : currentPositionDropdown - 1
          return position
        })
      }
      if (e.key === 'Enter') {
        e.preventDefault()
        const value = e.target.value
        if (showDropdown && isCurrentPositionDropdownActive) {
          const currentItem = dropdownList[currentPositionDropdown]
          checkItemToSetPredictLocation(currentItem)
        } else if (value && value.trim()) {
          locationUtils.searchAddress(
            value,
            extraInfos.country_code,
            (place: { addressName: SetStateAction<string>; addressComponents: any }) => {
              setInputValue(place.addressName)
              onClickPredictLocation({
                ...place,
                formatted_address: place.addressName,
                address_components: place.addressComponents,
              })
              setPredictionLocations([])
            },
            () => {
              // onAutoComplete()
            },
            extraInfos
          )
        }

        inputRef.current.blur()
        setIsInputFocused(false)
      }

      if (e.key === 'Tab') {
        setIsInputFocused(false)
      }

      if (onKeyDown) {
        onKeyDown(e)
      }
    }

    const handleOnKeyUp = (e: any) => {
      const textValue = e.target.value
      if (onKeyUp) {
        onKeyUp(e)
      }
      if (!['ArrowUp', 'ArrowDown'].includes(e.key)) {
        prevInputValueRef.current = textValue
      }
    }

    const updateInputValue = (e: any) => {
      const textValue = e.target.value
      onChange?.(textValue)
      setInputValue(textValue)
      if (textValue) {
        if (isPaste.current) {
          // we should set prevInputValue to show dropdown
          prevInputValueRef.current = e.target.value
          delayGetPredictLocationWidthSearch(textValue)
        } else {
          delayGetPredictLocations(textValue)
        }
      }
      isPaste.current = false

      if (isCurrentPositionDropdownActive) {
        setCurrentPositionDropdown(-1)
      }

      // reset when input is empty
      // onAutoComplete()
      setPredictionLocations([])
    }

    const handleClickDropdownItem = async (item: { name: SetStateAction<string> }) => {
      setIsInputFocused(false)
      if (showPredicts) {
        checkItemToSetPredictLocation(item)
      } else {
        onClickRecentLocation(item)
      }

      setInputValue(item.name)
      sessionToken.current = uuidv4()
    }

    const handleOnPaste = (e: any) => {
      // because OnPaste action don't get text value so we will handle on updateInputValue action
      isPaste.current = true
    }

    const onClickShowMore = () => {
      // we use "x-use-case": "show_more" for google autocomplete
      getPredictLocations(inputValue, X_USE_CASE.SHOW_MORE)
      clickShowMore(inputValue)
    }

    const isShowRecentLocation = !inputValue && recentLocations?.length
    return (
      <div ref={wrapperRef} className={clsx('address-input Input', className)}>
        <textarea
          {...props}
          ref={inputRef}
          onChange={updateInputValue}
          onFocus={focusTextInput}
          onClick={onClickTextArea}
          onKeyDown={handleKeyDown}
          onKeyUp={handleOnKeyUp}
          value={inputValue}
          onPaste={handleOnPaste}
          className={classTextArea}
        />
        {children && children}
        {showDropdown &&
          createPortal(
            <DropdownList
              ref={dropdownRef}
              listItems={dropdownList as any}
              onClickItem={handleClickDropdownItem}
              wrapperRect={wrapperRect}
              extraLeftSidePositionDropdown={extraLeftSidePositionDropdown}
              currentPositionDropdown={currentPositionDropdown}
              showMore={
                settingShowMore && !isShowRecentLocation && dropdownList?.[0]?.xUseCase !== X_USE_CASE.SHOW_MORE
              }
              onClickShowMore={onClickShowMore}
            />,
            portalRef as any
          )}
      </div>
    )
  }
)
AddressInput.defaultProps = {
  onClickRecentLocation: () => undefined,
  onClickPredictLocation: () => undefined,
  onKeyDown: () => undefined,
  onFocus: () => undefined,
  onClick: () => undefined,
  onKeyUp: () => undefined,
  defaultValue: '',
  onlyPredicts: true,
  className: '',
  extraLeftSidePositionDropdown: 0,
  extraInfos: {},
  isForceRerender: false,
  clickShowMore: () => undefined,
}

export default AddressInput
