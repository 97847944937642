import $ from 'jquery'
import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

// UTILS
import {
  getLocationDisplayInfos,
  LocationPopover,
} from 'utils/LocationUtil'
import contactUtils from 'utils/common/contact'
import { Utils } from 'utils/Utils'
import phoneNumberUtils from 'utils/common/phoneNumber'
import locationUtils from 'utils/common/location'
import currentLocationUtils from 'utils/common/getCurrentLocation'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// ACTIONS
import * as roundTripDiscountActionCreators from 'store/actions/new_booking/roundTripDiscountActionCreators'
import * as recentLocationsActionCreators from 'store/actions/common/recentLocationsActionCreators'
import * as contactActionCreators from 'store/actions/common/contactActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'

// COMPONENTS
import MyLocations from '../../common/contact/MyLocations'
import InputPhoneNumber from '../../common/InputPhoneNumber'
import DropdownActions from '../../common/DropdownActions'
import FormLocationPopup from '../../common/my-location/FormLocationPopup'
import CountdownRemaining from '../../common/CountdownRemaining'
// CONSTANTS
import {
  MIN_NUMBER_OF_LOCATIONS,
  ALLOW_DRIVER_TO_CALL,
  CHECK_CUSTOMER_MASK_PHONE,
  CLEAR_CUSTOMER_MASK_PHONE,
} from 'constants/newBookingConstants'
import { FULL_DAY, LONG_HAUL, STATUS_DRIVER_ACCEPT_BOOKING } from 'constants/bookingConstants'
import * as IMAGES from 'constants/imageConstants'
import { BUSINESS_PROGRAM, PERSONAL } from 'constants/common/contactConstants'
import {
  getParamFromURL,
  isEditBooking, setErrorMessage, setErrorText, setIsErrorFDMegazone
} from 'utils/booking/common'
import { currentVehicleType } from 'utils/new_booking/common'
import { withNavigate } from 'hocs/withNavigate'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import CommonUtils from 'utils/common'
import * as eventTracking from 'utils/trackingMoengage'
import AddressInput from 'components/common/AddressInput'
import clsx from 'utils/common/clsx'
import mapUtils from 'utils/common/map'
import { SOURCE_GOOGLE } from 'constants/common/locationConstants'
import { appsFlyerTrackEvent } from 'utils/trackingAppsFlyer'
import { DROP_OFF_PRESS, PICKUP_PRESS } from 'constants/trackingAppsFlyer'
import { COUNTRY_PH, COUNTRY_VN } from 'constants/dashBoardAnalyticsConstants'

// ASSETS
require('jquery-ui')
require('jquery-ui-bundle')


const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  bookAgainDetails: state.bookAgainDetails,
  locations: state.locations,
  googleMap: state.googleMap,
  mapService: state.mapService,
  currentCustomer: state.currentCustomer,
  contacts: state.contacts,
  personalContacts: state.personalContacts,
  extraInfos: state.extraInfos,
  timeType: state.timeType,
  dropOffZones: state.dropOffZones,
  serviceTypes: state.serviceTypes,
  roundTripDiscount: state.roundTripDiscount,
  selectedVehicleType: currentVehicleType(state),
  recentLocations: state.recentLocations,
  currentStep: state.currentStep,
  polyline: state.polyline
})

function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    roundTripDiscountActions: bindActionCreators(roundTripDiscountActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    recentLocationsActions: bindActionCreators(recentLocationsActionCreators, dispatch),
    contactActions: bindActionCreators(contactActionCreators, dispatch),
  }
}

class LocationCompletion extends React.Component {
  static combineNameAndCompanyName(contact) {
    return contact.company_name && contact.company_name.trim().length > 0 ? `${contact.name} - ${contact.company_name}` : contact.name
  }

  static handleNewValueLocation(currentTarget, keyCode) {
    const oldValue = currentTarget.value
    const startPositionNewCharacter = currentTarget.selectionStart
    const endPositionNewCharacter = currentTarget.selectionEnd
    let deleteText = ''
    if (startPositionNewCharacter === endPositionNewCharacter) {
      if (keyCode === 8) {
        deleteText = oldValue.slice(0, startPositionNewCharacter - 1) + oldValue.slice(endPositionNewCharacter)
      } else if (keyCode === 46) {
        deleteText = oldValue.slice(0, startPositionNewCharacter) + oldValue.slice(endPositionNewCharacter + 1)
      }
    } else {
      deleteText = oldValue.slice(0, startPositionNewCharacter) + oldValue.slice(endPositionNewCharacter)
    }
    const newValue = (keyCode === 8 || keyCode === 46)
      ? deleteText
      : oldValue.slice(0, startPositionNewCharacter)
      + String.fromCharCode(keyCode)
      + oldValue.slice(endPositionNewCharacter)
    return newValue
  }

  static handleChangeIcon(e, shouldTriggerEvent) {
    if (shouldTriggerEvent) $(e.currentTarget).find('label, img').toggleClass('hidden')
  }

  constructor(props) {
    super(props)
    const { extraInfos } = this.props
    const countryCodeValue = extraInfos.country_code?.toLowerCase()
    this.state = {
      addressFirstLocation: '',
      checkPhoneMask: undefined,
      countryCode: countryCodeValue,
      isOSA: false,
      isOSAAddress: {
        name: '',
        lat: '',
        lng: '',
      },
      isErrorDistance: false,
      errorDistanceAddress: {
        name: '',
        lat: '',
        lng: '',
      },
      isAddMeClicked: false,
      isForceRerender: false,
    }
    this.lastLocationTracked = createRef()

    this.handleGetPlace = this.handleGetPlace.bind(this)
    this.selectContact = this.selectContact.bind(this)
    this.handleMapPlaceToContactInfo = this.handleMapPlaceToContactInfo.bind(this)
    this.childMyLocations = []
    this.addMe = this.addMe.bind(this)
    this.handleCustomerPhoneChange = this.handleCustomerPhoneChange.bind(this)
    this.handleCustomerPhoneBlur = this.handleCustomerPhoneBlur.bind(this)
    this.handleCustomerPhoneFocus = this.handleCustomerPhoneFocus.bind(this)
    this.setIsAddMeClicked = this.setIsAddMeClicked.bind(this)

    this.isLoadFromMarketingAtFirst = React.createRef()
  }

  componentDidMount() {
    const {
      bookAgainDetails,
      location,
      googleMap,
      mapService,
      extraInfos,
      stepActions,
      index,
      isEnableGoogleMap,
    } = this.props
    const isMKT = getParamFromURL('is_mkt')
    const addressDefault = JSON.parse(localStorage.getItem('addressDefault'))
    const isActiveDraggable = bookAgainDetails?.status === STATUS_DRIVER_ACCEPT_BOOKING

    if (isMKT) this.isLoadFromMarketingAtFirst.current = true
    if ((isEnableGoogleMap && googleMap) || mapService) {
      // for book again flow
      if (!_.isEmpty(bookAgainDetails) && !_.isUndefined(location.lat) && !_.isUndefined(location.lng)) {
        this.makeNewLocationMarker(
          location.name,
          location.lat,
          location.lng,
          undefined,
          true,
          location.address_components,
        )
      }
      if (isEditBooking()) {
        stepActions.toggleDraggableMarkers(isActiveDraggable)
      }

      // Default click from address after loaded from area page to get first address
      if (index === 0 && addressDefault) {
        const firstAddress = document.getElementById(`booking_locations_attributes_${location.id}_name`)
        firstAddress.click()
      }
    }

    // Check default location address
    if (extraInfos?.default_center_address) {
      this.setFirstLocationAddress(extraInfos.default_center_address)
    }

    LocationPopover.addClass($('.Menu'), 'fixed fixed-width')
  }

  componentWillReceiveProps(nextProps) {
    const {
      timeType,
      location,
      locations,
      index,
      outsideList,
      errorDistanceList,
      setOutsideList,
      locationActions,
      googleMap,
      mapService,
      extraInfos,
      fullLoadView,
      isEnableGoogleMap,
      polyline
    } = this.props
    const { locations: nextLocations, removeIndexOutsideList, } = nextProps
    const { isOSAAddress, errorDistanceAddress } = this.state
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView

    if ((isFulldayMegazone || !fullLoadView) && !_.isEqual(nextProps.outsideList, outsideList)) {
      locationActions.drawBookingLocationRoute('', nextProps.outsideList)
      if(isEnableGoogleMap && polyline && polyline.length) {
        polyline.forEach(line => {
          line.strokeColor = !_.isEmpty(nextProps.outsideList) ? '#f04433' : 'rgba(14,115,15,1)'
        });
      }
    }
    if (nextProps.timeType !== FULL_DAY && timeType !== nextProps.timeType) {
      locationActions.drawBookingLocationRoute()
      if(isEnableGoogleMap && polyline && polyline.length) {
        polyline.forEach(line => {
          line.strokeColor = !_.isEmpty(nextProps.outsideList) ? '#f04433' : 'rgba(14,115,15,1)'
        });
      }
    }
    if (location.marker
      && (timeType !== nextProps.timeType)
      && (timeType === LONG_HAUL || nextProps.timeType === LONG_HAUL)
    ) {
      location.marker.setDraggable(timeType === LONG_HAUL)
    }
    const isLazyAddress = locations[index]?.lazyAddress?.[locations[index]?.id]
    const isNextLazyAddress = nextLocations[index]?.lazyAddress?.[nextLocations[index]?.id]
    const isNextOSA = _.includes(nextProps.outsideList, nextLocations[index]?.id)
    if (isNextLazyAddress && isNextOSA) {
      removeIndexOutsideList(nextLocations[index].id)
    }
    if (
      !isLazyAddress
      && !_.isEqual(errorDistanceList, nextProps.errorDistanceList)
    ) {
      setOutsideList([])
      let isEmptyLocations = false
      if (!_.isEmpty(nextLocations[index])) {
        if (errorDistanceAddress.lat !== nextLocations[index].lat
          && errorDistanceAddress.lng !== nextLocations[index].lng) {
          isEmptyLocations = true
        }
      }
      if (_.includes(nextProps.errorDistanceList, locations[index].id)) {
        if (_.isEmpty(errorDistanceAddress || isEmptyLocations)) {
          this.setState({
            errorDistanceAddress: {
              name: nextLocations[index].name,
              lat: nextLocations[index].lat,
              lng: nextLocations[index].lng,
            }
          })
        }
        this.handleIsErrorDistance(true)
        this.handleShowTypeTooltip(locations[index].id, true)
      } else {
        this.handleIsErrorDistance(false)
        this.handleShowTypeTooltip(locations[index].id, false)
      }
    }
    if (
      !isLazyAddress
      && !_.isEqual(nextProps.outsideList, outsideList)
      && _.isEmpty(nextProps.errorDistanceList)
    ) {
      let isEmptyLocations = false
      if (!_.isEmpty(nextLocations[index])) {
        if (isOSAAddress.lat !== nextLocations[index].lat && isOSAAddress.lng !== nextLocations[index].lng) {
          isEmptyLocations = true
        }
      }
      if (_.includes(nextProps.outsideList, locations[index].id)) {
        if (_.isEmpty(isOSAAddress) || isEmptyLocations) {
          this.setState({
            isOSAAddress: {
              name: nextLocations[index].name,
              lat: nextLocations[index].lat,
              lng: nextLocations[index].lng,
            }
          })
        }
        this.handleIsOSA(true)
        this.handleShowTypeTooltip(locations[index].id, true)
        
        eventTracking.eventSetLocation({
          ...this.lastLocationTracked.current,
          isOSA: true,
        })
      } else {
        this.handleIsOSA(false)
        this.handleShowTypeTooltip(locations[index].id, false)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      fullLoadView,
      isErrorInput,
      handleRemoveErrorInput,
      extraInfos: currExtraInfos,
      currentStep
    } = this.props
    const { extraInfos: prevExtraInfos, location: prevPropsLocation } = prevProps
    if (!location?.lat || !location?.lng) return false
    if ((location.lat !== prevPropsLocation?.lat || location.lng !== prevPropsLocation.lng)
      || this.isLoadFromMarketingAtFirst.current
    ) {
      this.isLoadFromMarketingAtFirst.current = false
      if (!location.address_components || location.address_components.length === 0) {
        if(location.contact_id) {
          this.handleLazyAddressForContact()
        } else {
          locationUtils.handleCallGeocodeAPI({ address: location.name }, (results) => {
            if (results) {
              const { addressComponent } = locationUtils.getDataResponse(results[0])
              const matchLocation = results.find(x => x?.addressName === location.name)
              const addressComponents = matchLocation
                ? matchLocation?.addressComponents
                : addressComponent
              this.handleValidateLazyAddress({ ...location, address_components: addressComponents })
            }
          }, currExtraInfos)
        }
      } else {
        this.handleValidateLazyAddress(location)
      }
    }
    if (!fullLoadView && isErrorInput) {
      handleRemoveErrorInput()
    }
    // Check default location address
    if (prevExtraInfos?.default_center_address !== currExtraInfos?.default_center_address) {
      this.setFirstLocationAddress(currExtraInfos.default_center_address)
    }
  
    if(prevProps.currentStep !== currentStep) {
      this.setState(prevState => ({ isForceRerender: !prevState.isForceRerender }))
    }
  }

  handleLazyAddressForContact = () => {
    // magic logic: address_components is null and enable lazy-address => we always show error
    const { extraInfos, locationActions, location } = this.props
    const {
      components_specific_address: componentSpecificAddress,
      components_validation_1: componentValidation1,
      components_validation_2: componentValidation2,
    } = extraInfos || {}

    const isValidToShowError = !_.isEmpty(componentSpecificAddress) || !_.isEmpty(componentValidation1) || !_.isEmpty(componentValidation2)
    if(!isValidToShowError) return
    const lazyAddressError = locationUtils.getInvalidLazyAddressMessage([], extraInfos)
    if(lazyAddressError) {
      locationActions.updateLazyAddressLocation(location.id, lazyAddressError)
    } else {
      locationActions.updateLazyAddressLocation(location.id, undefined)
    }
  }

  getBookingLocationName = () => {
    const { index } = this.props
    return index === 0 ? "pickup" : `destination ${index}`
  }

  setFirstLocationAddress(address) {
    this.setState({ addressFirstLocation: address })
  }
  // TODO: disable react/sort-comp
  /* eslint-disable-next-line */
  isRoundTripLocation() {
    const {
      roundTripDiscount, location, locations, isDisabledContact, isDisabledInputShopping, fullLoadView
    } = this.props
    if (isDisabledContact) {
      return isDisabledInputShopping
    }
    return fullLoadView && roundTripDiscount && location.id === _.last(locations).id
  }

  isRemoveLocationHaveRoundTrip() {
    const {
      roundTripDiscount,
      location,
      locations,
    } = this.props
    return roundTripDiscount && _.size(locations) === 3 && (location.id !== _.last(locations).id)
  }

  removeLocation() {
    const {
      assignedDriver, location, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    if (assignedDriver && _.isNumber(location.lat) && _.isNumber(location.lng)) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.removeLocationAfterCheckBookingDetails.bind(this) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.removeLocationAfterCheckBookingDetails()
    }
  }

  removeLocationAfterCheckBookingDetails() {
    const {
      location,
      locationActions,
      roundTripDiscountActions,
      extraInfos,
      timeType,
      outsideList,
      fullLoadView,
    } = this.props
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView

    this.resetMarker()
    if (this.isRoundTripLocation() || this.isRemoveLocationHaveRoundTrip()) {
      roundTripDiscountActions.update(false)
      this.removeAutoCompleteLocation()
    } else {
      locationActions.removeLocation(location, isFulldayMegazone && outsideList)
    }
    locationActions.updateOptimizeState(false)
  }

  removeAutoCompleteLocation() {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions, location,
      removeIndexOutsideList, removeIndexErrorDistanceList
    } = this.props
    const { isOSA, isErrorDistance } = this.state
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.removeAutoCompleteLocationAfterCheckBookingDetails.bind(this) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.removeAutoCompleteLocationAfterCheckBookingDetails()
    }
    if (isErrorDistance || isOSA) {
      this.handleShowTypeTooltip(location.id, false)
    }
    removeIndexErrorDistanceList()
    removeIndexOutsideList(location.id)
  }

  removeAutoCompleteLocationAfterCheckBookingDetails() {
    const {
      isDisabledContact: setDefaultZoom
    } = this.props
    Promise.resolve(
      this.resetLocation()
    ).then(() => {
      // this.address.value = ''
      this.address.closest('.Input').classList.remove('error')
      this.errorMessage.innerHTML = ''
      if (setDefaultZoom) {
        this.setZoomMapDefault()
      }
    })
  }

  resetLocation() {
    this.resetMarker()
    const {
      location,
      locationActions,
      isDisabledContact: setDefaultZoom,
    } = this.props
    const locationAttrs = {
      lat: undefined,
      lng: undefined,
      name: undefined,
      marker: undefined,
      contact_id: undefined,
    }
    locationActions.actionUpdateLocation(location.id, locationAttrs, undefined, false)
    locationActions.updateOptimizeState(false)
    if (setDefaultZoom && $(this.address).val() === '') {
      this.setZoomMapDefault()
    }
  }

  /**
   * Set value for isAddMeClicked state
   * @param {Boolean} isAddMeClicked
   */
  setIsAddMeClicked(isAddMeClicked) {
    this.setState({ isAddMeClicked })
  }

  setZoomMapDefault() {
    const {
      extraInfos, isFirstLocation, locations
    } = this.props
    if (isFirstLocation && !locations[1].marker) {
      const latLng = extraInfos.default_center_lat_lng.split(',')
      this.zoomInPickup({ lat: latLng[0], lng: latLng[1] }, extraInfos.default_map_zoom)
    }
  }

  resetMarker() {
    const { location, isEnableGoogleMap } = this.props
    if (location.marker) {
      if (isEnableGoogleMap) {
        location.marker.setMap(null)
        window.google.maps.event.clearListeners(location.marker, 'dragend')
      } else {
        location.marker.remove();
      }
    }
  }

  handleClosePopover(id) {
    const { isDisabledContact, setFocusInputID } = this.props
    LocationPopover.closeClass($(`#booking_locations_attributes_${id}`), 'visible')
    if (this.childMyLocations && this.childMyLocations[id] && !isDisabledContact) {
      this.childMyLocations[id].selectContact(undefined)
    }
    setFocusInputID('')
  }

  handleCloseTooltip(id) {
    const { isDisabledContact } = this.props
    LocationPopover.closeClass($(`#booking_locations_attributes_${id}_tooltip`), 'visible')
    if (this.childMyLocations && this.childMyLocations[id] && !isDisabledContact) {
      this.childMyLocations[id].selectContact(undefined)
    }
  }

  handleShowDropOffMarker() {
    const {
      isFirstLocation,
      location,
      locations,
      extraInfos,
      googleMap,
      mapService,
      locationActions,
      isEnableGoogleMap
    } = this.props
    if (!isFirstLocation && !location.lat && !location.lng) {
      locationActions.removeBlurMarker(isEnableGoogleMap)
      const lat = locations[0].lat || extraInfos.default_center_lat_lng.split(',')[0]
      const lng = locations[0].lng || extraInfos.default_center_lat_lng.split(',')[1]
  
      let marker = null
      const distance = isEnableGoogleMap ? 1000 : 1
      const [dropOffLat, dropOffLng] = locationUtils.calculateLatLngByDistance(lat, lng, isEnableGoogleMap, distance)

      if (isEnableGoogleMap) {
        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(dropOffLat, dropOffLng),
          map: googleMap.map,
          animation: window.google.maps.Animation.DROP,
          draggable: true
        })
      } else {
        marker = mapUtils.setMarker(mapService, dropOffLng, dropOffLat)
      }

      this.zoomInPickup(
        {
          lat: dropOffLat,
          lng: dropOffLng
        },
        extraInfos.default_pickup_zoom
      )

      const attrs = {
        lat: undefined,
        lng: undefined,
        address_components: undefined,
        marker,
        contact_id: undefined
      }
      locationActions.actionUpdateLocation(location.id, attrs, undefined, false)
      this.googleMapAddListener(marker, undefined)
    }
  }

  showNotePopup = (event, isShowMyLocationsPopup = false) => {
    const { location, setFocusInputID, index, currentCustomer, extraInfos } = this.props
    const { isOSA, isErrorDistance } = this.state
    if (isOSA || isErrorDistance) {
      this.handleShowTooltip()
    } else {
      this.handleShowNotePopup(event)
      this.handleShowMyLocationsPopup(isShowMyLocationsPopup)
      this.handleShowDropOffMarker()
      LocationPopover.addClass($('body'), 'Step-Route')
    }
    if (isShowMyLocationsPopup) {
      this.handleShowNotePopup(event)
      this.handleShowMyLocationsPopup(isShowMyLocationsPopup)
      this.handleShowDropOffMarker()
      LocationPopover.addClass($('body'), 'Step-Route')
    }
    setFocusInputID(location.id)
    if(index > 0 && (extraInfos?.country_code === COUNTRY_PH || extraInfos?.country_code === COUNTRY_VN)) {
      appsFlyerTrackEvent(DROP_OFF_PRESS, { customer_id: currentCustomer.id })
    }
    if(index === 0) {
      appsFlyerTrackEvent(PICKUP_PRESS, { customer_id: currentCustomer.id, company_id: currentCustomer.last_login_employ_id })
    }
  }

  handleShowTooltip() {
    LocationPopover.closeClass($('.Info.Popover'), 'visible')

    const config = {
      type: 'new-booking',
      popover: 30,
      top: 70,
      arrow: 74
    }
    const elementConfig = {
      element: $(this.locationTooltip),
      arrow_element: $(this.locationTooltipArrow),
      element_left_position: '100%',
      element_index_position: 12,
      arrow_element_position: 15
    }
    LocationPopover.init(
      this.address,
      $(this.locationTooltip).height(),
      0,
      config,
      elementConfig
    )
  }

  handleShowNotePopup(event) {
    const { addressFirstLocation } = this.state
    const {
      location,
      locations,
      locationActions,
      isFirstLocation,
      bookAgainDetails,
      extraInfos,
      googleMap,
      mapService,
      isEnableGoogleMap
    } = this.props
    LocationPopover.closeClass($('.Info.Popover'), 'visible')

    const config = {
      type: 'new-booking',
      popover: 30,
      top: 70,
      arrow: 74
    }
    const elementConfig = {
      element: $(this.locationPopover),
      arrow_element: $(this.locationPopoverArrow),
      element_left_position: '100%',
      element_index_position: 12,
      arrow_element_position: 15
    }
    LocationPopover.init(
      this.address,
      $(this.locationPopover).height(),
      0,
      config,
      elementConfig
    )

    const address = event?.target?.value
    const isOnlyPickupAddress = address === '' && isFirstLocation && _.isUndefined(location.name) && locations.length !== 1 && _.isUndefined(locations[1].name)

    if (isOnlyPickupAddress) {
      const addressDefault = JSON.parse(localStorage.getItem('addressDefault'))
      let locationAttrs
      if (addressDefault) {
        const { address_components: addressComponents, formatted_full_address: positionAddress } = addressDefault
        const { lat, lng } = addressDefault.geometry.location
        locationAttrs = currentLocationUtils.setDefaultAddress({
          addressFirstLocation,
          mapService,
          extraInfos,
          position: {
            latitude: lat,
            longitude: lng
          },
          positionAddress,
          addressComponents,
          googleMap, isEnableGoogleMap
        })
      } else {
        locationAttrs = currentLocationUtils.setDefaultAddress({
          addressFirstLocation, mapService, extraInfos, googleMap, isEnableGoogleMap
        })
      }
      if (locationAttrs) {
        this.updateLocationCompletion(locationAttrs)
      }
    }
    if (_.isUndefined(location.contact_id) && _.isUndefined(location.phone_mask) && isFirstLocation
      && _.isEmpty(bookAgainDetails)) {
      const results = contactUtils.getPhoneMaskAndPhoneNumber(ALLOW_DRIVER_TO_CALL)
      locationActions.updateLocation(
        location.id,
        {
          phone_mask: results.phone_mask,
        }
      )
    }
  }

  handleShowAddLocationPopup() {
    const {
      currentCustomer,
      contactActions,
      extraInfos,
      location,
      fullLoadView
    } = this.props
    const currentTab = currentCustomer.current_company_id === 0 ? PERSONAL : BUSINESS_PROGRAM
    const renderNode = document.getElementById('CommonPopup')
    const newContact = {
      address: location.name,
      address_components: location.address_components,
      latitude: location.lat,
      longitude: location.lng,
    }

    ReactDOM.render(
      <FormLocationPopup
        contact={newContact}
        shouldShow
        currentCustomer={currentCustomer}
        extraInfos={extraInfos}
        contactActions={contactActions}
        currentTab={currentTab}
        handleContactsChange={this.childMyLocations[location.id].handleContactsChange}
        customClassAutoComplete="Step-Route"
        isPTL={!fullLoadView}
      />,
      renderNode
    )
  }

  handleShowMyLocationsPopup(isShow) {
    const { location, isDisabledContact } = this.props
    const locationID = location.id
    if (this.childMyLocations && this.childMyLocations[locationID]) {
      if (isShow || isDisabledContact) {
        this.childMyLocations[locationID].switchTabs(BUSINESS_PROGRAM)
      } else {
        this.childMyLocations[locationID].hideContacts()
      }
    }
  }

  handleIsOSA(value) {
    this.setState({
      isOSA: value
    })
  }

  handleIsErrorDistance(value) {
    this.setState({
      isErrorDistance: value
    })
  }

  /**
   * Show normal tooltip or OSA tooltip
   * @param {Number} id // locationID
  */
  handleShowTypeTooltip(id, isError) {
    // const { setFocusInputID } = this.props
    // setFocusInputID('')
    const input = document.getElementById(`booking_locations_attributes_${id}_name`)
    const popupNormal = document.getElementById(`booking_locations_attributes_${id}`)
    const popupError = document.getElementById(`booking_locations_attributes_${id}_tooltip`)
    if (popupNormal && popupError) {
      const normalClassName = popupNormal.className
      const errorClassName = popupError.className
      if (_.includes(normalClassName, 'visible') && isError) {
        const offsetTop = input.getBoundingClientRect().y
        popupError.style.top = `${offsetTop - 85}px`
        popupNormal.className = _.replace(normalClassName, ' visible', '')
        popupError.className = `${errorClassName} visible`
      }
      if (_.includes(errorClassName, 'visible') && !isError) {
        popupError.className = _.replace(errorClassName, ' visible', '')
        popupNormal.className = `${normalClassName} visible`
      }
    }
    return this
  }

  async getLocation() {
    const {
      currentPopupIDActions,
      infoAssignDriverPopupActions,
      assignedDriver,
    } = this.props
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.getLocationAfterCheckBookingDetails() },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.getLocationAfterCheckBookingDetails()
    }
  }

  async getLocationAfterCheckBookingDetails() {
    const {
      mapService, extraInfos, googleMap, isEnableGoogleMap, location, fullLoadView
    } = this.props
    const { isOSA } = this.state
    const locationAttrs = await currentLocationUtils.getLocationAfterCheckBookingDetails({
      mapService, extraInfos, googleMap, isEnableGoogleMap
    })
    if (locationAttrs) {
      if(location.marker) {
        mapUtils.removeMarker(location.marker, isEnableGoogleMap)
      }
      const setLocationObj = {
        source: "Current location",
        location: this.getBookingLocationName(),
        coordinate: { latitude: locationAttrs.lat, longitude: locationAttrs.lng },
        isPTL: !fullLoadView,
        isOSA,
      }

      eventTracking.eventSetLocation({
        ...setLocationObj
      })
      this.lastLocationTracked.current = {
        ...this.lastLocationTracked.current,
        ...setLocationObj,
      }
      this.updateLocationCompletion(locationAttrs)
    }
  }

  updateLocationCompletion(locationAttrs) {
    const {
      mapService,
      location,
      extraInfos,
      locationActions,
      googleMap, isEnableGoogleMap
    } = this.props
    locationActions.actionUpdateLocation(location.id, locationAttrs, locationAttrs.pinfrom, false)
    if (locationAttrs.lat && locationAttrs.lng && ((isEnableGoogleMap && googleMap?.map) || mapService)) {
      if(isEnableGoogleMap) {
        googleMap.map.setCenter(new window.google.maps.LatLng(locationAttrs.lat, locationAttrs.lng))
      } else {
        mapService.setCenter([locationAttrs.lng, locationAttrs.lat])
      }
      this.zoomInPickup(locationAttrs, extraInfos.default_pickup_zoom)
    }
    locationActions.updateOptimizeState(false)
    const address = $(this.address)
    address.val(locationAttrs.name)
    address.closest('.Input').removeClass('error')
    this.googleMapAddListener(locationAttrs.marker, locationAttrs.contact_id)
  }

  setLocationByDragMarker(marker, contactId) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions, stepActions
    } = this.props
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.setLocationByDragMarkerAfterCheckBookingDetails.bind(this, marker, contactId),
          backFunc: this.setPreviousMarker.bind(this)
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
      stepActions.toggleDraggableMarkers(false)
    } else {
      this.setLocationByDragMarkerAfterCheckBookingDetails(marker, contactId)
    }
  }

  setLocationByDragMarkerAfterCheckBookingDetails(marker, contactId) {
    const {
      location, locationActions, stepActions, recentLocationsActions, currentCustomer, outsideList,
      timeType, extraInfos, fullLoadView, isEnableGoogleMap
    } = this.props
    const { isOSA } = this.state
    if(isEnableGoogleMap) {
      if (!location?.marker?.draggable) {
        stepActions.toggleDraggableMarkers(true)
      }
    } else {
      if (!location?.marker?.isDraggable()) {
        stepActions.toggleDraggableMarkers(true)
      }
    }
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView
    const latlng = locationUtils.latlngGenerate(marker, isEnableGoogleMap)
    locationUtils.handleCallGeocodeAPI({ latlng }, (results) => {
      if (results) {
        const { addressComponent, formattedAddress } = locationUtils.getDataResponse(results[0])
        const { lat, lng } = mapUtils.getLatLngFromMarker(marker, isEnableGoogleMap)
        const attrs = {
          lat: lat,
          lng: lng,
          name: formattedAddress,
          address_components: addressComponent,
          marker,
          contact_id: contactId || ''
        }
        locationActions.actionUpdateLocation(location.id, attrs, undefined, false, isFulldayMegazone && outsideList)
        locationActions.updateOptimizeState(false)
        // work around for address not changing although location has been updated
        const address = $(this.address)
        address.val(attrs.name)

        const shouldSetRecentLocation = _.every([lat, lng, attrs.name])
        if (shouldSetRecentLocation) {
          recentLocationsActions.setRecentLocation(currentCustomer, attrs)
        }
        const setLocationObj = {
          source: "Choose from map",
          location: this.getBookingLocationName(),
          coordinate: { latitude: lat, longitude: lng },
          isPTL: !fullLoadView,
          isOSA,
        }
        eventTracking.eventSetLocation({
          ...setLocationObj,
        })
        this.lastLocationTracked.current = {
          ...this.lastLocationTracked.current,
          ...setLocationObj,
        }
      }
    }, extraInfos)
  }

  setPreviousMarker() {
    const { location, stepActions, extraInfos } = this.props
    const address = $(this.address)
    const self = this
    stepActions.toggleDraggableMarkers(true)
    locationUtils.handleCallGeocodeAPI({ address: location.name }, (results) => {
      if (results) {
        const { addressComponent, lat, lng } = locationUtils.getDataResponse(results[0])
        self.makeNewLocationMarker(
          location.name,
          lat,
          lng,
          undefined,
          false,
          addressComponent
        )
      } else {
        $(address).val('')
        self.resetLocation()
      }
    }, extraInfos)
  }

  googleMapAddListener(marker, contactId) {
    if (!marker) return
    const { isEnableGoogleMap } = this.props
    if(isEnableGoogleMap) {
      window.google.maps.event.addListener(marker, 'dragend', () => {
        this.setLocationByDragMarker(marker, contactId)
      })
  
      window.google.maps.event.addListener(marker, 'click', (/* event */) => {
        const { location, extraInfos } = this.props
        if (location.lat && location.lng && location.name) {
          this.zoomInPickup(location, extraInfos.default_pickup_zoom)
        }
      })
    } else {
      marker.on('dragend', () => {
        this.setLocationByDragMarker(marker, contactId)
      });
      marker.getElement().addEventListener('click', (e) => {
        const { location, extraInfos } = this.props
        if (location.lat && location.lng && location.name) {
          this.zoomInPickup(location, extraInfos.default_pickup_zoom)
        }
      })
    }
  }

  selectContact({ contact }) {
    const { isDisabledContact: keepContactBooker } = this.props
    this.setContact(contact, true, keepContactBooker)
  }

  setContact(contact, refLocations = false, keepContactBooker = false) {
    const {
      assignedDriver,
      location,
      currentPopupIDActions,
      infoAssignDriverPopupActions
    } = this.props
    const sameLat = location.lat === contact.latitude
    const sameLng = location.lng === contact.longitude
    if (assignedDriver && refLocations && !sameLat && !sameLng) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.setContactAfterCheckBookingDetails.bind(this, contact, keepContactBooker) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
      this.handleClosePopover(location.id)
    } else {
      this.setContactAfterCheckBookingDetails(contact, keepContactBooker)
    }
  }

  setContactAfterCheckBookingDetails(contact, keepContactBooker) {
    const {
      location,
      currentCustomer,
      locationActions,
      recentLocationsActions,
      extraInfos,
      fullLoadView
    } = this.props
    const { isOSA } = this.state

    const result = phoneNumberUtils.formatPhoneNumber(
      contact.phone, currentCustomer.country_code || extraInfos.country_code?.toLowerCase(), true
    )
    const newContactPhone = result.phoneNumber
    $(`#booking_locations_attributes_${location.id}_recipient_name`).val(LocationCompletion.combineNameAndCompanyName(contact))
    $(`#booking_locations_attributes_${location.id}_recipient_phone`).val(newContactPhone)
    const address = $(this.address)
    const errorMessage = $(this.errorMessage)
    const self = this
    if (!_.isEmpty(contact.address)) {
      address.val(contact.address)
      address.closest('.Input').removeClass('error')
      errorMessage.html('')
      if (_.isNumber(contact.latitude) && _.isNumber(contact.longitude)) {
        this.makeNewLocationMarker(
          contact.address, contact.latitude, contact.longitude, contact.id, false, contact.address_components
        )
        const setLocationObj = {
          source: "My location (saved location)",
          location: this.getBookingLocationName(),
          coordinate: contact,
          isPTL: !fullLoadView,
          isOSA,
        }

        eventTracking.eventSetLocation({
          ...setLocationObj,
        })
        this.lastLocationTracked.current = {
          ...this.lastLocationTracked.current,
          ...setLocationObj,
        }
      } else {
        $(address).val('')
        self.resetLocation()
      }
    }
    let instantContactId
    if (contact.company_id || contact.customer_id) {
      instantContactId = contact.id
    }
    const updateLocationAttrs = {
      recipient_name: keepContactBooker ? currentCustomer.name : contact.name,
      recipient_phone: keepContactBooker ? currentCustomer.phone : newContactPhone,
      description: contact.address_detail || '',
      contact_id: instantContactId,
      phone_mask: contact.phone_mask,
      location_name: contact.location_name,
      address_components: contact.address_components,
    }
    if (contact.location_name && location.lat && location.lng && location.name && contact.address_components) {
      const recentLocation = {
        ...location,
        ...updateLocationAttrs
      }
      recentLocationsActions.setRecentLocation(currentCustomer, recentLocation)
    }
    locationActions.updateLocation(
      location.id,
      updateLocationAttrs
    )
    this.zoomInPickup(self.props.location, extraInfos.default_pickup_zoom)
  }

  addMe(id) {
    const { currentCustomer } = this.props
    const { checkPhoneMask } = this.state
    if (!_.isUndefined(checkPhoneMask)) {
      clearTimeout(checkPhoneMask)
    }
    const results = contactUtils.getPhoneMaskAndPhoneNumber(ALLOW_DRIVER_TO_CALL)
    this.setContact({
      ...currentCustomer,
      phone_mask: results.phone_mask
    })
    if (this.childMyLocations && this.childMyLocations[id]) {
      this.childMyLocations[id].selectContact(undefined)
    }
    this.setIsAddMeClicked(true)
  }

  makeNewLocationMarker(address, lat, lng, contactID, flowBookAgain = false, addressComponents = []) {
    const {
      location, locationActions,
      mapService,
      timeType,
      extraInfos,
      outsideList,
      fullLoadView,
      googleMap,
      isEnableGoogleMap,
    } = this.props
    this.resetMarker()
    let marker
    if (isEnableGoogleMap) {
      const latLng = new window.google.maps.LatLng(lat, lng)

      marker = new window.google.maps.Marker({
        position: latLng,
        map: googleMap.map,
        animation: window.google.maps.Animation.DROP,
      })
    } else {
      marker = mapUtils.setMarker(mapService, lng, lat)
    }
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView
    marker.setDraggable(timeType !== LONG_HAUL)
    const locationAttrs = {
      lat,
      lng,
      name: address,
      marker,
      contact_id: contactID,
      address_components: addressComponents,
    }
    mapUtils.handleFitBounds({
      mapService, googleMap, isEnableGoogleMap,
      coordinates: [{ lng, lat }]
    })

    $(this.address).closest('.Input').removeClass('error')
    $(this.errorMessage).html('')
    locationActions.actionUpdateLocation(
      location.id, locationAttrs, undefined, flowBookAgain, isFulldayMegazone && outsideList
    )
    if (!flowBookAgain) {
      locationActions.updateOptimizeState(false)
    }
    this.googleMapAddListener(marker, contactID)
  }

  placeholder() {
    const {
      timeType, index,
      isDisabledContact: isShoppingTitle,
      fullLoadView,
    } = this.props
    if (!fullLoadView) {
      return index === 0 ? I18n.t('webapp.new_booking.step_1.select_pickup_ltl') : I18n.t('webapp.new_booking.step_1.select_destination_ltl')
    }
    if (isShoppingTitle) {
      return index === 0 ? I18n.t('webapp.new_booking.step_1.select_store_pickup') : I18n.t('webapp.new_booking.step_1.select_store_destination')
    }
    if (timeType === FULL_DAY) {
      return index === 0 ? I18n.t('webapp.new_booking.step_1.select_pickup_full_day') : I18n.t('webapp.new_booking.step_1.select_destination_full_day')
    }
    return index === 0 ? I18n.t('webapp.new_booking.step_1.select_pickup') : I18n.t('webapp.new_booking.step_1.select_destination')
  }

  isMiniumLocationCount() {
    const {
      timeType, locations,
    } = this.props
    return timeType === FULL_DAY
      ? locations.length === 1
      : locations.length <= MIN_NUMBER_OF_LOCATIONS
  }

  /* eslint-disable-next-line */
  zoomInPickup(location, zoom) {
    const { mapService, googleMap, isEnableGoogleMap } = this.props
    if(isEnableGoogleMap && googleMap.map) {
      googleMap.map.setZoom(zoom)
      googleMap.map.setCenter(new window.google.maps.LatLng(location.lat, location.lng), zoom)
    } else {
      mapService.setZoom(zoom)
      location.lng && location.lat && mapService.setCenter([location.lng, location.lat])
    }

  }

  placeChangedListenGoogleMap = (place, isSearchAddress = false) => {
    const {
      assignedDriver,
      location,
      currentPopupIDActions,
      infoAssignDriverPopupActions,
      currentCustomer
    } = this.props

    let eventAction
    let eventLabel

    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.placeChangedListenGoogleMapAfterCheckBookingDetails.bind(this, place, isSearchAddress),
          backFunc: this.setPreviousLocation.bind(this, location)
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.placeChangedListenGoogleMapAfterCheckBookingDetails(place, isSearchAddress)
    }
    if (!currentCustomer.id) {
      eventAction = 'API Autocomplete Location with anonymous'
      eventLabel = 'Check call API Autocomplete'
    } else {
      eventAction = 'API Autocomplete Location with User or Company'
      eventLabel = `CustomerId: ${currentCustomer.id}, CompanyId: ${currentCustomer.current_company_id}`
    }
    Utils.sentEventTrackingtoGA('Places API', eventAction, eventLabel)
  }

  setPreviousLocation(location) {
    const { locationActions } = this.props
    locationActions.actionUpdateLocation(location.id, { name: '', lat: undefined, lng: undefined })
  }

  async handleValidateLazyAddress(location) {
    const { extraInfos, locationActions } = this.props
    if (location.name && location.lat && location.lng) {
      const isValid = await locationUtils.validateLazyAddress(location, extraInfos, null, null, null, (lazyAddressError) => {
        locationActions.updateLazyAddressLocation(location.id, lazyAddressError)
      })
      if (isValid && location.lazyAddress?.[location.id]) {
        locationActions.updateLazyAddressLocation(location.id, undefined)
      }
    }
  }

  placeChangedListenGoogleMapAfterCheckBookingDetails(place, isSearchAddress) {
    const { fullLoadView } = this.props
    // const addressName = $(this.address)
    const addressValue = place.formatted_address
    const { lat, lng } = locationUtils.getLatLngOld(place?.location)
    this.makeNewLocationMarker(
      addressValue,
      lat,
      lng,
      undefined, false, place.address_components
    )
    const {
      location,
      extraInfos,
      handleLocationUpdate,
    } = this.props
    const { isOSA } = this.state

    const newLocation = {
      ...location, lat, lng, address_components: place.address_components, name: place.formatted_address,
    }
    this.zoomInPickup(newLocation, extraInfos.default_pickup_zoom)
    handleLocationUpdate(newLocation, {
      name: addressValue,
      lat,
      lng
    })

    const isGoogle = place?.source === SOURCE_GOOGLE
    const setLocationObj = {
      source: `Location search - ${isGoogle ? 'Google' :'DB'}`,
      location: this.getBookingLocationName(),
      coordinate: place?.location,
      isPTL: !fullLoadView,
      isOSA,
    }
    eventTracking.eventSetLocation({
      ...setLocationObj,
    })

    this.lastLocationTracked.current = {
      ...this.lastLocationTracked.current,
      ...setLocationObj,
    }
  }

  handleGetPlace(item) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions
    } = this.props
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.handleGetPlaceAfterCheckBookingDetails.bind(this, item) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.handleGetPlaceAfterCheckBookingDetails(item)
    }
  }

  handleGetPlaceAfterCheckBookingDetails(item) {
    const {
      extraInfos,
      location,
      handleLocationUpdate,
      fullLoadView
    } = this.props
    const { isOSA } = this.state

    // reset
    const addressName = $(`#booking_locations_attributes_${location.id}_name`)
    addressName.val(item.name)
    if (_.isNull(item.contact_id)) {
      this.makeNewLocationMarker(
        item.name,
        item.latitude,
        item.longitude,
        undefined,
        false,
        item.address_components
      )

    }
    const setLocationObj = {
      source: "Recently used",
      location: this.getBookingLocationName(),
      coordinate: item,
      isPTL: !fullLoadView,
      isOSA,
    }

    eventTracking.eventSetLocation({
      ...setLocationObj,
    })

    this.lastLocationTracked.current = {
      ...this.lastLocationTracked.current,
      ...setLocationObj,
    }

    this.zoomInPickup(location, extraInfos.default_pickup_zoom)
    handleLocationUpdate(location, {
      name: item.name,
      lat: item.latitude,
      lng: item.longitude
    }, true)
    if (!_.isNull(item.contact_id)) {
      this.handleMapPlaceToContactInfo(item)
    }
  }

  handleMapPlaceToContactInfo(item) {
    const contact = {
      address: item.name,
      address_detail: item.address_detail,
      contact_type: 'normal',
      id: item.contact_id,
      location_name: item.location_name,
      name: item.pic_name,
      phone: item.pic_number,
      latitude: item.latitude,
      longitude: item.longitude,
      address_components: item.address_components,
    }
    if (item.user_type === 'Company') {
      contact.company_id = item.user_id
    } else {
      contact.customer_id = item.user_id
    }
    this.setContact(contact)
  }

  // autocompleteResult = (results) => {
  //   const input = this.address
  //   const errorMessage = this.errorMessage
  //   const latLngPattern = new RegExp(LAT_LNG_SEARCH_PATTERN)

  //   if (Array.isArray(results) && results.length <= 0 && !latLngPattern.test(input.value.trim())) {
  //     input.closest('.Input').classList.add('error')
  //     errorMessage.innerHTML = I18n.t('webapp.new_booking.step_1.location_not_found')
  //   } else {
  //     input.closest('.Input').classList.remove('error')
  //     errorMessage.innerHTML = ''
  //   }
  // }

  isValidForRoundTripDiscount(params) {
    const { roundTripDiscount, shouldShowRoundTripDiscount, locations } = this.props
    if (roundTripDiscount) {
      const validDropOffs = _.filter(locations, (location, index) => (
        index !== 0
        && index !== _.size(locations) - 1
        && (!_.isUndefined(location.lat) || !_.isUndefined(location.lng))
      ))
      if ((!shouldShowRoundTripDiscount && roundTripDiscount !== params.roundTripDiscount)
        || _.size(validDropOffs) === 0) {
        return true
      }
    }
    return false
  }

  pinZoomInPickup() {
    const { location, extraInfos } = this.props
    if (location.lat && location.lng && location.name) {
      this.zoomInPickup(location, extraInfos.default_pickup_zoom)
    }
  }

  checkChangeLocation(event, location) {
    const currentTarget = event.target
    const oldValue = currentTarget.value
    const keyCode = event.which
    const newValue = LocationCompletion.handleNewValueLocation(currentTarget, keyCode)
    if (newValue !== oldValue && (event.which !== 8 || event.which !== 46)) {
      this.checkShowingReassignDriverPopup(event, event.target, newValue, location)
    }
  }

  checkChangeLocationKeyDown = (event, location) => {
    const { locationActions } = this.props
    const keyCode = event.which
    const currentTarget = event.target
    const { value } = currentTarget
    if (!_.isEmpty(value) && (event.which === 8 || event.which === 46)) {
      this.checkShowingReassignDriverPopup(currentTarget)
    }

    // if (!_.isEmpty(value) && keyCode === 13) {
    //   const { area } = this.props
    //   locationUtils.searchAddress(
    //     value,
    //     area.country_code,
    //     (place) => {
    //       this.placeChangedListenGoogleMap(place, true)
    //     },
    //     () => {
    //       $(this.address).closest('.Input').addClass('error')
    //       $(this.errorMessage).html(I18n.t('webapp.new_booking.step_1.location_not_found'))
    //     }
    //   )
    // }
    if (location.lazyAddress?.[location.id]) {
      locationActions.actionUpdateLocation(location.id, { lat: undefined, lng: undefined })
      locationActions.updateLazyAddressLocation(location.id, undefined)
    }
  }

  handleOnKeyUp = (event) => {
    const value = event.target.value
    if (_.isEmpty(value) && (event.which === 8 || event.which === 46)) {
      this.resetLocation()
    }
  }

  checkShowingReassignDriverPopup = (currentTarget) => {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    if (assignedDriver) {
      currentTarget.blur()
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: () => this.resetLocation()
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    }
  }

  handleChangeLocation = (value, location) => {
    const {
      locationActions,
      removeIndexOutsideList,
      outsideList,
      setOutsideList,
      setListErrorDistance,
      errorDistanceList,
      removeIndexErrorDistanceList,
      extraInfos,
      timeType,
      isErrorInput,
      handleRemoveErrorInput,
      handleErrorPickupLocation,
      fullLoadView
    } = this.props
    const {
      isOSA, isOSAAddress, isErrorDistance, errorDistanceAddress
    } = this.state
    const { name } = isOSAAddress
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView
    if (isErrorInput) {
      handleRemoveErrorInput()
      handleErrorPickupLocation(false)
    }
    if (isErrorDistance) {
      removeIndexErrorDistanceList()
      this.handleShowTypeTooltip(location.id, false)
    }
    if (isOSA && _.isEmpty(errorDistanceList)) {
      this.handleShowTypeTooltip(location.id, false)
      removeIndexOutsideList(location.id)
    }
    if (name === value && !_.isEmpty(value)) {
      const newList = [...outsideList]
      newList.push(location.id)
      setOutsideList(newList)
    }
    if (errorDistanceAddress.name === value && !_.isEmpty(value)) {
      const newErrorDistanceList = [...errorDistanceList]
      newErrorDistanceList.push(location.id)
      setListErrorDistance(newErrorDistanceList)
    }
    locationActions.actionUpdateLocation(
      location.id, { name: value, lat: undefined, lng: undefined }, undefined, false, isFulldayMegazone && outsideList
    )
  }

  handleCustomerPhoneChange(phone) {
    const { location, locationActions } = this.props
    locationActions.updateLocation(location.id, { recipient_phone: phone })
  }

  handleCustomerPhoneFocus(phone) {
    const {
      location, currentCustomer, locationActions
    } = this.props
    let attrs = { phone_mask: location.phone_mask, recipient_phone: phone }
    let shouldClear = !currentCustomer.show_phone_number_to_driver
    shouldClear = _.isUndefined(location.contact_id) ? shouldClear && !_.isUndefined(location.phone_mask) : shouldClear
    if (shouldClear) {
      attrs = contactUtils.getPhoneMaskAndPhoneNumber(CLEAR_CUSTOMER_MASK_PHONE, {
        recipient_phone: phone,
        customer_phone: currentCustomer.phone,
      })
    }
    locationActions.updateLocation(
      location.id,
      {
        phone_mask: attrs.phone_mask,
        recipient_phone: attrs.recipient_phone
      }
    )
  }

  handleCustomerPhoneBlur(phone) {
    const {
      location, currentCustomer, locationActions
    } = this.props
    const timeout = currentCustomer.show_phone_number_to_driver ? 0 : 250
    let shouldClear = !currentCustomer.show_phone_number_to_driver
    shouldClear = _.isUndefined(location.contact_id) ? shouldClear && !_.isUndefined(location.phone_mask) : shouldClear
    const checkPhoneMask = setTimeout(() => {
      let attrs = { phone_mask: location.phone_mask, recipient_phone: phone }
      if (shouldClear) {
        attrs = contactUtils.getPhoneMaskAndPhoneNumber(CHECK_CUSTOMER_MASK_PHONE, {
          recipient_phone: phone,
          customer_phone: currentCustomer.phone,
        })
      }
      locationActions.updateLocation(
        location.id,
        {
          phone_mask: attrs.phone_mask,
          recipient_phone: attrs.recipient_phone
        }
      )
    }, timeout)
    this.setState({ checkPhoneMask })
  }

  onClickAddressInput = (e) => {
    const { fullLoadView } = this.props
    this.showNotePopup(e)
    eventTracking.selectLocationField(this.getBookingLocationName(), !fullLoadView)
  }

  renderPopover(existedCustomer, useLocationPhoneMask) {
    const {
      location,
      locationActions,
      contacts,
      personalContacts,
      isFirstLocation,
      isDisabledContact,
      currentCustomer,
      extraInfos,
      stepActions,
      contactActions,
      focusInputID,
    } = this.props
    const {
      checkPhoneMask,
      countryCode,
      isAddMeClicked,
    } = this.state
    return (
      <div
        ref={(ref) => { this.locationPopover = ref }}
        className="Info Popover custom"
        id={`booking_locations_attributes_${location.id}`}
        style={{
          top: '10px',
          bottom: 'auto',
        }}
      >
        <span className="BoxCustom-Close" onClick={() => this.handleClosePopover(location.id)}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
        <div
          ref={(ref) => { this.locationPopoverArrow = ref }}
          className="Popover-Arrow"
          style={{ top: '24px' }}
        />
        {existedCustomer && (
          <div className="Popover-Item actions">
            <MyLocations
              currentCustomer={currentCustomer}
              extraInfos={extraInfos}
              stepActions={stepActions}
              contactActions={contactActions}
              buttonText={I18n.t('contacts.labels.add_from_contact')}
              emptyMessage={I18n.t('booking_details.notify.no_contacts')}
              handleSelectContact={this.selectContact}
              contacts={contacts}
              personalContacts={personalContacts}
              className="Popover-Item Selectbox-Contact Selectbox-Contact-Customs active"
              isPickupLocation={isFirstLocation}
              locationContactID={location.contact_id}
              focusInputID={focusInputID}
              onRef={(ref) => { this.childMyLocations[location.id] = ref }}
              isAlwaysRenderContact={isDisabledContact}
              customClassAutoComplete="Step-Route"
              isAddMeClicked={isAddMeClicked}
              setIsAddMeClicked={this.setIsAddMeClicked}
            />
            {!isDisabledContact && (
              <div className="flex-index ml5 mr5">
                <button
                  type="button"
                  onClick={() => this.addMe(location.id)}
                  className="white-text white-border with-icon Button w100"
                  disabled={!existedCustomer}
                >
                  {I18n.t('contacts.labels.add_me')}
                </button>
              </div>
            )}
          </div>
        )}
        <div className="Popover-Item">
          <div className="FormGroup">
            <label className="FormGroup-label capitalize mar5">
              {I18n.t('contacts.labels.contact_name')}
            </label>
            <div className={`Input Hide-Border ${isDisabledContact ? 'Input-Disabled' : ''}`}>
              <input
                type="text"
                id={`booking_locations_attributes_${location.id}_recipient_name`}
                name={`booking[locations_attributes]["${location.id}"][recipient_name]`}
                tabIndex={1}
                value={location.recipient_name || ''}
                onChange={e => locationActions.updateLocation(location.id, { recipient_name: e.target.value })}
                disabled={isDisabledContact}
              />
            </div>
          </div>
        </div>
        <div className="Popover-Item">
          <div className="FormGroup">
            <label className="FormGroup-label capitalize mar5">
              {I18n.t('contacts.labels.phone_number')}
            </label>
            {useLocationPhoneMask ? (
              <div className={`Input Hide-Border ${isDisabledContact ? 'Input-Disabled' : ''}`}>
                <input
                  type="text"
                  id={`booking_locations_attributes_${location.id}_recipient_phone`}
                  name={`booking[locations_attributes]["${location.id}"][recipient_phone]`}
                  tabIndex={1}
                  value={location.phone_mask}
                  onBlur={e => this.handleCustomerPhoneBlur(e.target.value)}
                  onFocus={e => this.handleCustomerPhoneFocus(e.target.value)}
                  disabled={isDisabledContact}
                />
              </div>
            ) : (
              <InputPhoneNumber
                isWebApp
                closestElement={`location-${location.id}-number`}
                telInputProps={{ tabIndex: 1 }}
                phoneNumber={location.recipient_phone}
                countryCode={countryCode}
                fieldId={`booking_locations_attributes_${location.id}_recipient_phone`}
                phoneField={`booking[locations_attributes]["${location.id}"][recipient_phone]`}
                onPhoneChange={this.handleCustomerPhoneChange}
                onPhoneBlur={this.handleCustomerPhoneBlur}
                onPhoneFocus={this.handleCustomerPhoneFocus}
                checkPhoneMask={checkPhoneMask}
                disabled={isDisabledContact}
              />
            )}
          </div>
        </div>
        <div className="Popover-Item">
          <div className="FormGroup">
            <label className="FormGroup-label mar5">
              {I18n.t('contacts.labels.address_detail')}
            </label>
            <div className="note TextArea Hide-Border">
              <CountdownRemaining
                className="w100"
                classTextarea="default-font h80px"
                id={`booking_locations_attributes_${location.id}_description`}
                name={`booking[locations_attributes][${location.id}][description]`}
                placeholder={I18n.t('contacts.labels.address_detail')}
                value={location.description || ''}
                remainPercentToShow={extraInfos.remaining_characters_in_note_fields}
                handleChange={value => locationActions.updateLocation(location.id, { description: value })}
                disabled={isDisabledContact}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTooltip() {
    const {
      location,
      extraInfos,
      index,
      timeType,
      selectedVehicleType,
      fullLoadView
    } = this.props
    const { isErrorDistance } = this.state
    const { full_day_megazone_main_radius: radius } = extraInfos
    const locale = I18n.language
    const url = Utils.getOSAUrl(extraInfos.country_code, locale, index)
    const isErrorFDMegazone = setIsErrorFDMegazone(timeType, extraInfos.enable_full_day_megazone, index)
    const megazoneBlogLink = selectedVehicleType.full_day_megazone_blog_link || extraInfos.full_day_megazone_blog_link
    if (this.locationTooltip) {
      if (isErrorDistance && fullLoadView) {
        this.locationTooltip.classList.add('tooltip-error-distance')
      } else if (isErrorFDMegazone && fullLoadView) {
        this.locationTooltip.classList.remove('tooltip-error-distance')
        this.locationTooltip.classList.add('dlvr-tooltip-error-fd-megazone')
      } else {
        this.locationTooltip.classList.remove('dlvr-tooltip-error-fd-megazone')
        this.locationTooltip.classList.remove('tooltip-error-distance')
      }
    }
    return (
      <div
        ref={(ref) => { this.locationTooltip = ref }}
        className="Info Popover Popover-xl custom"
        id={`booking_locations_attributes_${location.id}_tooltip`}
        style={{
          top: '10px',
          bottom: 'auto',
        }}
      >
        <span className="BoxCustom-Close" onClick={() => this.handleCloseTooltip(location.id)}>
          <i className="b material-icons Icon">
            close
          </i>
        </span>
        <div
          ref={(ref) => { this.locationTooltipArrow = ref }}
          className="Popover-Arrow"
          style={{ top: '24px' }}
        />
        <div className="Popover-Item">
          <div className="FormGroup">
            {!isErrorDistance && isErrorFDMegazone && fullLoadView && (
              <div className="dlvr-fd-megazone-title">
                {I18n.t('webapp.new_booking.step_1.outside_radius_title')}
              </div>
            )}
            <label className={`FormGroup-label ${(!isErrorDistance && !isErrorFDMegazone) && 'capitalize mr5'} dlvr-osa-text-tooltip`}>
              {setErrorMessage(isErrorDistance, isErrorFDMegazone, radius, fullLoadView)}
            </label>
            {!isErrorDistance
              && (
                <a className="dlvr-hyper-link" href={isErrorFDMegazone ? megazoneBlogLink : url} target="_blank" rel="noopener noreferrer">
                  {isErrorFDMegazone && fullLoadView ? I18n.t('webapp.new_booking.step_1.error_learn_more_megazone') : I18n.t('webapp.new_booking.step_1.map_of_service_area')}
                </a>
              )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      index,
      location,
      locations,
      currentCustomer,
      fullLoadView,
      isFirstLocation,
      isDisabledContact,
      isDisabledInputShopping,
      errorDistanceList,
      extraInfos,
      timeType,
      isErrorInput,
      recentLocations,
    } = this.props
    const { isOSA, isErrorDistance, isForceRerender } = this.state
    const locationDisplayInfos = getLocationDisplayInfos(locations.length, index)
    const useLocationPhoneMask = !_.isEmpty(location.phone_mask) && !currentCustomer.show_phone_number_to_driver
    const shouldTriggerEvent = !this.isRoundTripLocation() && !this.isMiniumLocationCount() && index > 0
    const isErrorFDMegazone = setIsErrorFDMegazone(timeType, extraInfos.enable_full_day_megazone, index)
    const placeholder = this.placeholder()
    const disabled = this.isRoundTripLocation()
    const classForTracking = index === 0 ? 'input-pickup' : index === 1 ? 'input-destination' : ''
    const errorLazyAddress = !(isErrorDistance || isOSA) && location.lazyAddress && location.lazyAddress?.[location.id]
    const isErrorLadyAddress = location.lazyAddress && !!location.lazyAddress?.[location.id]
    return (
      <div id={`location-completion-${location.id}`}>
        {this.renderTooltip()}
        {this.renderPopover(currentCustomer.id, useLocationPhoneMask)}

        <div className="block-item">
          {!this.isRoundTripLocation()
            && !this.isMiniumLocationCount()
            && index > 0
            && fullLoadView
            && (
              <div className="relative">
                <div className="Location-Menu-Icon absoute" style={{ left: -10 }}>
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            )
          }
          <div
            className={locationDisplayInfos.className}
            onClick={() => this.pinZoomInPickup()}
            onMouseEnter={e => LocationCompletion.handleChangeIcon(e, shouldTriggerEvent)}
            onMouseLeave={e => LocationCompletion.handleChangeIcon(e, shouldTriggerEvent)}
          >
            <label>
              {locationDisplayInfos.label}
            </label>
            <img className="hidden" src={IMAGES.MOVE_DRAG_ICON} />
          </div>
          <AddressInput
            ref={(ref) => {
              this.address = ref?.textarea
            }}
            className={clsx(
              ((isErrorDistance || (isOSA && _.isEmpty(errorDistanceList)) || isErrorInput) || isErrorLadyAddress) && 'dlvr-error-input-border',
              disabled && 'Light-Gray-bg',
              classForTracking,
            )}
            id={`booking_locations_attributes_${location.id}_name`}
            name={`booking[locations_attributes][${location.id}][name]`}
            index={index}
            defaultValue={location.name}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={this.showNotePopup}
            onClick={this.onClickAddressInput}
            onKeyUp={this.handleOnKeyUp}
            onChange={e => this.handleChangeLocation(e, location)}
            onKeyDown={e => this.checkChangeLocationKeyDown(e, location)}
            onClickRecentLocation={this.handleGetPlace}
            onClickPredictLocation={result => this.placeChangedListenGoogleMap(result)}
            // onAutoComplete={this.autocompleteResult}
            recentLocations={recentLocations}
            extraInfos={extraInfos}
            onlyPredicts={false}
            isForceRerender={isForceRerender}
            clickShowMore={(value) => eventTracking.getGoogleLocationsResults(index === 0 ? "pickup" : `destination ${index}`, value)}
          >
            {/* {Dropdown Actions} */}
            <DropdownActions
              theme="#FFFFFF"
              className="Input-Icon Input-Hover-Icon h36px"
              classNameOnClick="visible-imp"
              id={location.id}
              isArrow
              renderLayout={() => <img src={IMAGES.HAMBUGER_COMMON_ICON} />}
              source={[
                {
                  id: 1,
                  isShow: currentCustomer.id && !this.isRoundTripLocation(),
                  title: I18n.t('webapp.new_booking.step_1.add_from_my_location'),
                  icon: IMAGES.LOCATION_COMMON_ICON,
                  onClick: () => this.showNotePopup(undefined, true)
                },
                {
                  id: 2,
                  isShow: !!(!this.isRoundTripLocation() && !!location.name && !!location.lat && !!location.lng),
                  title: I18n.t('webapp.new_booking.step_1.clear_address'),
                  icon: IMAGES.CLEAR_COMMON_ICON,
                  onClick: () => {
                    this.removeAutoCompleteLocation()
                    CommonUtils.moengageTrackEvent("Clear Location", {
                      "Booking location": index === 0 ? "pickup" : `destination ${index}`,
                      "Product": fullLoadView ? "FTL" : "PTL"
                    })
                  }
                },
                {
                  id: 3,
                  isShow: !this.isMiniumLocationCount() && index > 0,
                  title: I18n.t('webapp.new_booking.step_1.remove_destination', { index }),
                  icon: IMAGES.REMOVE_COMMON_ICON,
                  onClick: () => this.removeLocation()
                },
                {
                  id: 4,
                  isShow: isFirstLocation && !(isDisabledContact && isDisabledInputShopping),
                  title: I18n.t('webapp.new_booking.step_1.use_current_location'),
                  icon: IMAGES.GPS_COMMON_ICON,
                  onClick: () => this.getLocation()
                },
                {
                  id: 5,
                  isShow: !!location.name && location.lat && location.lng && currentCustomer.id && !(isDisabledContact && isDisabledInputShopping),
                  title: I18n.t('webapp.new_booking.step_1.save_location'),
                  icon: IMAGES.SAVE_COMMON_ICON,
                  onClick: () => this.handleShowAddLocationPopup()
                },
              ]}
            />
          </AddressInput>
        </div>
        {((isErrorDistance || (isOSA && _.isEmpty(errorDistanceList))) && !isErrorInput) && (
          <div className="dlvr-osa-text">
            <img className="dlvr-ic-err-code" src={IMAGES.ICON_IC_ERR_CODE} alt="error_code" />
            <span className="pl5">
              {setErrorText(isErrorDistance, isErrorFDMegazone, fullLoadView)}
            </span>
          </div>
        )}
        <div
          ref={(ref) => { this.errorMessage = ref }}
          id={`booking_locations_attributes_${location.id}_error`}
          className="FormGroup-explanation red locations-error"
        />
        {!!errorLazyAddress && (
          <div className="FormGroup-explanation red locations-error">
            <span className="message-error icon-error red">
              {errorLazyAddress}
            </span>
          </div>
        )}
      </div>
    )
  }
}

LocationCompletion.propTypes = {
  assignedDriver: PropTypes.shape({}),
  bookAgainDetails: PropTypes.shape({}),
  currentCustomer: PropTypes.shape({}).isRequired,
  locations: PropTypes.instanceOf(Array).isRequired,
  roundTripDiscount: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
    address_components: PropTypes.instanceOf(Array),
    name: PropTypes.string,
    lazyAddress: PropTypes.shape({}),
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  area: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  isFirstLocation: PropTypes.bool.isRequired,
  handleLocationUpdate: PropTypes.func.isRequired,
  outsideList: PropTypes.instanceOf(Array).isRequired,
  errorDistanceList: PropTypes.instanceOf(Array).isRequired,
  setListErrorDistance: PropTypes.func.isRequired,
  removeIndexOutsideList: PropTypes.func.isRequired,
  removeIndexErrorDistanceList: PropTypes.func.isRequired,
  setOutsideList: PropTypes.func.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({
    updateLazyAddressLocation: PropTypes.func
  }).isRequired,
  roundTripDiscountActions: PropTypes.shape({}).isRequired,
  contactActions: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  shouldShowRoundTripDiscount: PropTypes.bool,
  isDisabledContact: PropTypes.bool,
  isDisabledInputShopping: PropTypes.bool,
  recentLocationsActions: PropTypes.shape({}).isRequired,
  contacts: PropTypes.instanceOf(Array),
  personalContacts: PropTypes.instanceOf(Array),
  timeType: PropTypes.string,
  fullLoadView: PropTypes.bool,
  setFocusInputID: PropTypes.func,
  focusInputID: PropTypes.string,
  selectedVehicleType: PropTypes.shape({}),
  isErrorInput: PropTypes.bool,
  handleRemoveErrorInput: PropTypes.func,
  handleErrorPickupLocation: PropTypes.func,
  recentLocations: PropTypes.arrayOf(PropTypes.shape({})),
  isEnableGoogleMap: PropTypes.bool
}

LocationCompletion.defaultProps = {
  assignedDriver: undefined,
  bookAgainDetails: {},
  shouldShowRoundTripDiscount: false,
  isDisabledContact: false,
  isDisabledInputShopping: false,
  contacts: [],
  personalContacts: [],
  timeType: undefined,
  fullLoadView: true,
  setFocusInputID: () => undefined,
  focusInputID: '',
  selectedVehicleType: {},
  isErrorInput: false,
  handleRemoveErrorInput: () => undefined,
  handleErrorPickupLocation: () => undefined,
  recentLocations: [],
  isEnableGoogleMap: true
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(LocationCompletion))
