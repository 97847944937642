import _ from 'lodash'
import $ from 'jquery'
import LocationAPI from 'api/locations'
import { LAT_LNG_SEARCH_PATTERN } from 'constants/appConstants'
import { FULL_DAY, LONG_HAUL, NOW, IMMEDIATE, SCHEDULE } from 'constants/bookingConstants'
import { LAZY_SHOW_TOAST_MESSAGE } from 'constants/common/locationConstants'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast'
import mapUtils from './map'

const getEmptyLocations = (locations) =>
  locations
    .map((location, index) => {
      const invalid = _.isUndefined(location.lat) || _.isUndefined(location.lng)
      return invalid ? index : undefined
    })
    .filter((index) => index !== undefined)

const locationUtils = {
  invalidLocationIndexes(timeType, locations, roundTripDiscount = false) {
    let arrIndexes = []
    if (timeType === FULL_DAY) {
      // full_day require pickup location
      const pickupLocation = locations[0]
      if (_.isEmpty(pickupLocation) || _.isUndefined(pickupLocation.lat) || _.isUndefined(pickupLocation.lng)) {
        return [0]
      }
    } else {
      const filledLocationIndexes = _.filter(
        locations,
        (location) => location.lat !== undefined || location.lng !== undefined
      )
      // require pickup and atleast one drop-off
      if (filledLocationIndexes.length < 2) {
        arrIndexes = getEmptyLocations(locations)
      } else {
        const pickupLocation = locations[0]
        if (_.isEmpty(pickupLocation) || _.isUndefined(pickupLocation.lat) || _.isUndefined(pickupLocation.lng)) {
          arrIndexes = [0]
        }
        if (roundTripDiscount && filledLocationIndexes.length === 2) {
          arrIndexes = getEmptyLocations(locations)
        }
      }
    }
    // CHECK LOCATIONS HAVE NOT VALID BY GOOGLE AND INVALID LAZY ADDRESS
    _.forEach(locations, (l, index) => {
      if (_.isUndefined(l.lat) && _.isUndefined(l.lng) && _.size(l.name) > 0) {
        arrIndexes = _.concat(arrIndexes, index)
      }
    })

    return arrIndexes
  },
  removeEmptyLocations(locations) {
    const locationsTemp = { ...locations }
    return _.filter(locationsTemp, (l) => !_.isUndefined(l.lat) && !_.isUndefined(l.lng) && _.size(l.name) > 0)
  },
  fullDayWithoutDropOffs(locations) {
    return (
      locations.length === 2 &&
      locations[0].latitude === locations[1].latitude &&
      locations[0].longitude === locations[1].longitude
    )
  },
  filterEmptyLocations(locations) {
    return _.filter(locations, (l) => !_.isUndefined(l.lat) && !_.isUndefined(l.lng) && _.size(l.name) > 0)
  },
  clearCOD(locations) {
    const tmpLocations = []
    _.forEach(locations, (location) => {
      const params = { ...location }

      _.assign(params, { need_cod: false, cod_invoice_fees: '', cod_note: '' })
      tmpLocations.push(params)
    })
    return tmpLocations
  },
  isDisableOption(locations) {
    for (let i = 0; i < _.size(locations); i += 1) {
      const l = locations[i]
      if (_.isUndefined(l.lat) && _.isUndefined(l.lng) && _.size(l.name) > 0) {
        return true
      }
    }
    return false
  },
  locationPosition(location) {
    return {
      lat: _.isNaN(parseFloat(location.lat)) ? undefined : parseFloat(location.lat).toFixed(7),
      lng: _.isNaN(parseFloat(location.lng)) ? undefined : parseFloat(location.lng).toFixed(7),
    }
  },
  calculateLatLngByDistance(fromLat, fromLng, isEnableGoogleMap, distance, bearing = 90) {
    /*
     *distance by km
     *bearing by degrees
     */
    if (isEnableGoogleMap) {
      const pointA = new window.google.maps.LatLng(fromLat, fromLng)
      const pointB = window.google.maps.geometry.spherical.computeOffset(pointA, distance, bearing)
      return [pointB.lat(), pointB.lng()]
    }
    return mapUtils.getPointAtDistance(fromLat, fromLng, distance, bearing)
  },
  parseParamToGeocode(value, countryCode) {
    const latLngPattern = new RegExp(LAT_LNG_SEARCH_PATTERN)
    const params = {}
    const processedValue = value.trim()
    if (latLngPattern.test(processedValue)) {
      let lat = ''
      let lng = ''
      if (processedValue.includes(',')) {
        const processedValueSplitted = processedValue.split(',')
        lat = processedValueSplitted[0]
        lng = processedValueSplitted[1]
      } else {
        const processedValueSplitted = processedValue.split(' ')
        lat = processedValueSplitted[0]
        lng = processedValueSplitted[processedValueSplitted.length - 1]
      }
      params.latlng = `${lat},${lng}`
    } else {
      params.address = processedValue
      params.country = countryCode?.toLowerCase()
    }
    return params
  },
  searchAddress(value, countryCode, onSuccess, onFailed, extraInfos) {
    const params = this.parseParamToGeocode(value, countryCode)
    this.handleCallGeocodeAPI(
      params,
      (results) => {
        if (results) {
          onSuccess(results[0])
          return
        }
        if (onFailed) {
          onFailed()
        }
      },
      extraInfos
    )
  },
  /**
   * It decide which time type is suitable for entered locations
   * validatedLocationResult has an object in the following format
   * {
   *  "long_haul_address_valid": false,
      "is_inside": true,
      "is_inside_country": true,
      "out_of_service_area": {
        "is_surcharged": true,
      },
      "change_longhaul_to_schedule": false,
   * }
   */
  determineAcceptableTimeTypes(validatedLocationResult) {
    if (validatedLocationResult.long_haul_address_valid) {
      return [LONG_HAUL]
    }

    const { out_of_service_area: { is_surcharged: isSurcharged = false } = {}, is_inside: isInside } =
      validatedLocationResult || {}

    if (isInside) {
      return [NOW, IMMEDIATE, SCHEDULE, FULL_DAY]
    }

    if (isSurcharged) {
      return [NOW, IMMEDIATE, SCHEDULE, FULL_DAY]
    }

    return [NOW, IMMEDIATE, SCHEDULE]
  },
  validAddress(config, addressComponents) {
    let configArray
    if (_.isString(config)) configArray = config.split(',') || []
    if (_.isArray(config)) configArray = config || []
    configArray = configArray.map((item) => item.trim())
    if (_.isEmpty(configArray)) return false
    let locationAddressType = []
    addressComponents.forEach((element) => {
      locationAddressType = locationAddressType.concat(element.types)
    })
    const filteredAddressType = _.intersection(locationAddressType, configArray)
    // Check if location locationAddressType contains the configArray
    if (_.isEqual(_.sortBy(filteredAddressType), _.sortBy(configArray))) {
      return true
    }
    return false
  },
  async validateLazyAddress(location, extraInfos, inputError, errorMessage, showMessageType, cb) {
    const {
      components_specific_address: componentSpecificAddress,
      components_validation_1: componentValidation1,
      components_validation_2: componentValidation2,
    } = extraInfos || {}
    const isValidToShowError =
      !_.isEmpty(componentSpecificAddress) || !_.isEmpty(componentValidation1) || !_.isEmpty(componentValidation2)
    let addressComponentsTmp = location.address_components || []
    if (!_.isArray(addressComponentsTmp)) {
      addressComponentsTmp = []
    }
    if ((_.isEmpty(location) || _.isEmpty(addressComponentsTmp)) && isValidToShowError) {
      if (_.isEmpty(addressComponentsTmp)) {
        const isValidLazyAddress = await this.handleLazyAddressMissingComponents(
          location,
          extraInfos,
          inputError,
          errorMessage,
          showMessageType
        )
        return isValidLazyAddress
      }
      if (inputError) {
        inputError?.classList?.add('error')
      }
      if (showMessageType) {
        this.showInvalidLazyAddressMessage(errorMessage, addressComponentsTmp, extraInfos, showMessageType)
      }
      if (cb) {
        cb(this.showInvalidLazyAddressMessage(errorMessage, addressComponentsTmp, extraInfos, showMessageType))
      }
      return false
    }

    const arrValidate = [componentSpecificAddress, componentValidation1, componentValidation2]
    let statusValidate = 1
    if (arrValidate.length < 1) return false
    arrValidate.forEach((item) => {
      let configArr = []
      if (item && _.isString(item)) configArr = item.split(',')
      if (!_.isEmpty(item) && _.isArray(item)) configArr = item
      if (!_.isEmpty(configArr) && this.validAddress(item, addressComponentsTmp)) statusValidate = true
      else if (!_.isEmpty(configArr) && !this.validAddress(item, addressComponentsTmp) && statusValidate !== true)
        statusValidate = false
    })
    if ([1, true].indexOf(statusValidate) >= 0) return true
    if (inputError) {
      inputError?.classList?.add('error')
    }
    if (showMessageType) {
      this.showInvalidLazyAddressMessage(errorMessage, addressComponentsTmp, extraInfos, showMessageType)
    }
    if (cb) {
      cb(this.showInvalidLazyAddressMessage(errorMessage, addressComponentsTmp, extraInfos, showMessageType))
    }
    return false
  },

  handleLazyAddressMissingComponents(location, extraInfos, inputError, errorMessage, showMessageType) {
    const addressSearch = location.address || location.name
    return new Promise((resolve) => {
      this.handleCallGeocodeAPI(
        { address: addressSearch },
        (results) => {
          if (results) {
            const { addressComponent } = locationUtils.getDataResponse(results[0])
            const newLocation = {
              ...location,
              address_components: addressComponent,
            }
            resolve(this.validateLazyAddress(newLocation, extraInfos, inputError, errorMessage, showMessageType))
          }
          resolve(false)
        },
        extraInfos
      )
    })
  },

  getLazyAddressMessage(location, config = '') {
    let configArray = config ? config.split(',') : []
    configArray = configArray.map((item) => item.trim())
    if (_.isEmpty(configArray)) return ''

    if (!location || location.is_store) {
      return ''
    }

    if (!location.address_components) {
      return false
    }

    let locationAddressType = []
    location.address_components.forEach((element) => {
      locationAddressType = locationAddressType.concat(element.types)
    })

    const filteredAddressType = _.intersection(locationAddressType, configArray)
    // Check if location locationAddressType contains the configArray
    if (!_.isEmpty(filteredAddressType)) {
      return true
    }

    return false
  },

  errorLazyAddress(addressComponents = [], extraInfos = {}) {
    const {
      components_specific_address: specificAddress,
      components_validation_1: validation1,
      components_validation_2: validation2,
    } = extraInfos
    const turnOffLazyAddress = _.isEmpty(specificAddress) && _.isEmpty(validation1) && _.isEmpty(validation2)
    if (turnOffLazyAddress) return false

    const arrValidate = [specificAddress, validation1, validation2]
    let statusValidate = 1
    arrValidate.forEach((item) => {
      let configArr = []
      if (item && _.isString(item)) configArr = item.split(',')
      if (!_.isEmpty(item) && _.isArray(item)) configArr = item
      if (!_.isEmpty(configArr) && this.validAddress(item, addressComponents)) statusValidate = true
      else if (!_.isEmpty(configArr) && !this.validAddress(item, addressComponents) && statusValidate !== true)
        statusValidate = false
    })
    if ([1, true].indexOf(statusValidate) >= 0) return false
    return this.getInvalidLazyAddressMessage(addressComponents, extraInfos)
  },

  getAddressComponentsTypes(addressComponents) {
    const addressComponentsTypes = []
    _.map(addressComponents, (item) => addressComponentsTypes.push(item.types))
    return _.uniq(_.flatten(addressComponentsTypes))
  },

  formatFullTextLazyAddress(addressComponentsToValidate, addressComponentsTypes) {
    let fullTextLazyAddress = ''
    const differenceElements = _.differenceBy(addressComponentsToValidate, addressComponentsTypes)
    function mapIndexToText(index, diffElem) {
      if (index === 0) return ''
      if (_.size(diffElem) === index + 1) return ` ${I18n.t('webapp.new_booking.step_1.invalid_lazy_address.and')}`
      return ', '
    }
    _.forEach(differenceElements, (item, index) => {
      fullTextLazyAddress += mapIndexToText(index, differenceElements)
      fullTextLazyAddress += ` ${I18n.t(`webapp.new_booking.step_1.invalid_lazy_address.${item}`)}`
    })
    return I18n.t('webapp.new_booking.step_1.invalid_lazy_address.please_use_more', {
      invalidAddress: fullTextLazyAddress,
    })
  },

  convertStringToArray(componentValidation) {
    if (!_.isEmpty(componentValidation)) return componentValidation.replace(/\s/g, '').split(',')
    return []
  },

  getInvalidLazyAddressMessage(addressComponents, extraInfos) {
    const {
      components_validation_1: compValidate1,
      components_validation_2: compValidate2,
      components_specific_address: compSpecAddr,
    } = extraInfos || {}
    const componentsSpecificAddress =
      (_.isString(compSpecAddr) ? this.convertStringToArray(compSpecAddr) : compSpecAddr) || []
    const componentsValidation1 =
      (_.isString(compValidate1) ? this.convertStringToArray(compValidate1) : compValidate1) || []
    const componentsValidation2 =
      (_.isString(compValidate2) ? this.convertStringToArray(compValidate2) : compValidate2) || []
    const addressComponentsTypes = this.getAddressComponentsTypes(addressComponents)
    if (!componentsValidation2.every((item) => addressComponentsTypes.includes(item))) {
      return this.formatFullTextLazyAddress(componentsValidation2, addressComponentsTypes)
    }
    if (!componentsValidation1.every((item) => addressComponentsTypes.includes(item))) {
      return this.formatFullTextLazyAddress(componentsValidation1, addressComponentsTypes)
    }
    return this.formatFullTextLazyAddress(componentsSpecificAddress, addressComponentsTypes)
  },

  showInvalidLazyAddressMessage(errorElement, addressComponents, extraInfos, showMessageType) {
    const lazyAddressText = this.getInvalidLazyAddressMessage(addressComponents, extraInfos)
    switch (showMessageType) {
      case LAZY_SHOW_TOAST_MESSAGE:
        return toastr.error(lazyAddressText)
      default:
        return lazyAddressText
    }
  },

  latlngGenerate(marker, isEnableGoogleMap = true) {
    if (isEnableGoogleMap) {
      return `${marker.getPosition().lat()},${marker.getPosition().lng()}`
    }
    const lngLat = marker?.getLngLat()
    return `${lngLat.lat},${lngLat.lng}`
  },

  async handleCallGeocodeAPI(payload, callback, extraInfos) {
    let newXUseCase
    if (extraInfos && extraInfos.enable_trial_dlvr_maps) {
      newXUseCase = extraInfos.dlvr_maps_trial_usecase_name || null
    }
    const { data } = await LocationAPI.getGeocode(payload, newXUseCase)
    if (!_.isEmpty(data.results)) {
      callback?.(data.results)
      return data.results
    } else {
      callback?.(null)
      return null
    }
  },

  getDataResponse(data) {
    const { lat, lng } = this.getLatLng(data?.location)
    return {
      addressComponent: data?.addressComponents,
      formattedAddress: data?.addressName,
      lat,
      lng,
    }
  },
  getLatLng(location) {
    let lat = 0
    let lng = 0
    if (!_.isEmpty(location)) {
      lat = location.latitude
      lng = location.longitude
    }
    return {
      lat,
      lng,
    }
  },
  getLatLngOld(location) {
    let lat = 0
    let lng = 0
    if (!_.isEmpty(location)) {
      lat = _.isFunction(location.lat) ? location.lat() : location.lat
      lng = _.isFunction(location.lng) ? location.lng() : location.lng
    }
    return {
      lat,
      lng,
    }
  },
  isChangeLocations(locations = [], nextLocations = []) {
    for (let i = 0; i < nextLocations.length; i += 1) {
      const nextLocation = nextLocations[i]
      const currLocation = locations.find((location) => location.id === nextLocation.id)
      if (
        currLocation &&
        nextLocation.name &&
        nextLocation.lat &&
        nextLocation.lng &&
        (nextLocation.name !== currLocation.name ||
          nextLocation.lng !== currLocation.lng ||
          nextLocation.lat !== currLocation.lat)
      ) {
        return true
      }
    }
    return false
  },
  removeAccents(str) {
    return str
      .normalize('NFC')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  },
  hightLightSearchedKeyWords(address, matchStrings, countryCode) {
    const keywords = _.uniq(matchStrings)
    const arrPattern = []
    for (let i = 0; i < keywords.length; i += 1) {
      const keyword = keywords[i]
      const formatKeyword = keyword.trim()
      if (countryCode !== 'vn') {
        arrPattern.push(formatKeyword)
      }
      const arrKeyword = formatKeyword.split('')
      const arrChar = arrKeyword.map((character) => {
        const newChar = locationUtils.removeAccents(character).toLowerCase()
        switch (newChar) {
          case 'a':
            return '[aaàáãạảăắằẳẵặâấầẩẫậAÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬ]'
          case 'e':
            return '[eèéẹẻẽêềếểễệEÈÉẸẺẼÊỀẾỂỄỆ]'
          case 'i':
            return '[iìíĩỉịIÌÍĨỈỊ]'
          case 'y':
            return '[yỳỵỷỹýYỲỴỶỸÝ]'
          case 'u':
            return '[uùúũụủưứừửữựUÙÚŨỤỦƯỨỪỬỮỰ]'
          case 'o':
            return '[oòóõọỏôốồổỗộơớờởỡợOÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢ]'
          case 'd':
          case 'đ':
            return '[dđDĐ]'
          default:
            return character
        }
      })
      const pattern = arrChar.join('')
      arrPattern.push(pattern)
    }
    if (countryCode !== 'vn') {
      return address?.replace(new RegExp(arrPattern.join('|'), 'gi'), '<b>$&</b>')
    }
    return address?.normalize('NFC')?.replace(new RegExp(arrPattern.join('|'), 'gi'), '<b>$&</b>')
  },
  getAutoCompleteAddress: async ({ keyword, sessionToken, extraInfos, xUseCase }) => {
    const countryCode = extraInfos.country_code.toLowerCase()
    const query = {
      input: keyword,
      sessionToken,
      country: countryCode,
      ...(extraInfos.default_center_lat_lng && { bias_position: extraInfos.default_center_lat_lng }),
    }
    let newXUseCase = xUseCase
    if (!xUseCase && extraInfos.enable_trial_dlvr_maps) {
      newXUseCase = extraInfos.dlvr_maps_trial_usecase_name || null
    }
    const response = await LocationAPI.getPredictionLocations(query, newXUseCase)
    if (response.status === 200) {
      const { predictions } = response.data || []
      return predictions.map((location) => {
        const hasMatchStrings = location.match_strings && location.match_strings.length
        const matchString = hasMatchStrings ? location.match_strings : [keyword]
        return {
          ...location,
          id: location.place_id,
          xUseCase: newXUseCase,
          name: location.label,
          source: location.source,
          htmlName: locationUtils.hightLightSearchedKeyWords(location.label, matchString, countryCode),
        }
      })
    }
    toastr.error(response.data.error_message)
    return null
  },
  async getListSearchAddress(address, extraInfos, params) {
    const countryCode = extraInfos.country_code.toLowerCase()
    let newXUseCase
    if (extraInfos.enable_trial_dlvr_maps) {
      newXUseCase = extraInfos.dlvr_maps_trial_usecase_name || null
    }
    const res = await LocationAPI.getGeocode(params, newXUseCase)
    const results = res?.data?.results
    if (results?.length > 0) {
      return results.map((location) => {
        const hasMatchStrings = location.match_strings && location.match_strings.length
        const matchString = hasMatchStrings ? location.match_strings : [address]
        return {
          ...location,
          id: location.placeId,
          name: location.addressName,
          formatted_address: location.addressName,
          address_components: location.addressComponents,
          htmlName: locationUtils.hightLightSearchedKeyWords(location.addressName, matchString, countryCode),
        }
      })
    }
    return null
  },
  getPlaceDetail: async (place, sessiontoken) => {
    const query = {
      place_id: place.place_id,
      sessiontoken,
    }
    const { data } = await LocationAPI.getPlaceDetailApi(query, place.source)
    if (!data.errorMsg) {
      const placeDetails = data?.results[0] || []
      if (place.name) {
        placeDetails.addressName = place.name
      }
      return {
        ...placeDetails,
        formatted_address: placeDetails.addressName,
        address_components: placeDetails.addressComponents,
        xUseCase: place.xUseCase,
      }
    }
    toastr.error(data.errorMsg)
    return null
  },
}

export default locationUtils
